import React, { Component } from "react";
import { cookie_list, getCookie } from "../../utils/cookie";
import { WEBSITE_NAME, FOLDER_NAME } from "../../utils/process";
import TitleNText from "../../components/titleNtext";
import { content } from '../../constants/content';
import { Card, Row, Col, Typography, Button, Space, Collapse, Carousel, Image, Spin, notification } from 'antd';
import { PlusOutlined, MinusOutlined, LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import { apiCall } from "../../utils/http";
import JobSearchBox from '../jobsearchbox';
import { notificationContent } from '../../constants/notifications';

const { Panel } = Collapse;
const { Title, Text } = Typography;
let apiKey = process.env.REACT_APP_API_KEY
//NOTIFICATION POPUP
function notificationPop(type, header, message) {
  notification.destroy()
  notification[type]({
    message: header,
    description: message
  })
}
class EmployerDetails extends Component {
  constructor() {
    super();
    this.state = {
      token: getCookie(cookie_list.TOKEN) || "",
      data: null,
      buyerId:null,
      companyPhotos:null,
      ourValues:null,
      questionAnswers:null,
      benefits:null,
      loading:false,
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.carousel = React.createRef();
  }
  next() {
    this.carousel.next();
  }
  previous() {
    this.carousel.prev();
  }
  assignOurValus(ourValuesArr, index, value, isTitle=false){
    if(value) {
      let finalOurValuesArr=[];
      let isobjExsists = ourValuesArr.find((item)=>item.valueNo===index);
      if(isobjExsists) {
        finalOurValuesArr=ourValuesArr.map((ourValuesItem)=>{
          if(ourValuesItem.valueNo===index){
            if(isTitle){ourValuesItem.title=value}else{ourValuesItem.description=value}
          }
          return ourValuesItem;
        })
      }else{
        finalOurValuesArr.push(...ourValuesArr, {valueNo:index,title:isTitle?value:null,description:isTitle?null:value})
      }
      return finalOurValuesArr;
    }
    return ourValuesArr;
  }
  setPhotos=(data)=>{
    let photos=[],values=[],questions=[],benefitList=[];
    for (const key in data) {
        if(key.includes("CompanyPhoto")){
            photos.push(data[key]);
        }
        for (let index = 1; index <= 5; index++) {
          let ourValuesText="OurValues"+index;
          let ourValuesTitleText="OurValues"+index+"Title";
          let questionText="Question"+index;
          let answerText="Question"+index+"Answer";
          switch (key) {
            case ourValuesTitleText: values = this.assignOurValus(values,index,data[key],true); //if(data[key]){values.push({valueNo:index,title:data[key],description:null})}
              break;
            case ourValuesText:  values = this.assignOurValus(values,index,data[key],false);
              break;
            case questionText: if(data[key]){questions.push({qNo:index,question:data[key],ans:null})}
              break;
            case answerText:
                            questions=questions.map((questionItem)=>{
                              if(questionItem.qNo===index){
                                questionItem.ans=data[key];
                              }
                              return questionItem;
                            })
              break;
            default:
              break;
          }
        }
    }
    for(let index=1; index<=9; index++){
      if(data["Benefits"+index+"Title"] || data["Benefits"+index]){
        benefitList.push({"title": data["Benefits"+index+"Title"],"description": data["Benefits"+index]});
      }
    }
    this.setState({companyPhotos:photos, ourValues:values, questionAnswers: questions, benefits:benefitList});
  };
  getEmplyorData=async()=>{
    this.setState({loading:true});
    const response= await apiCall('Employer/'+this.state.buyerId+'?apiKey='+apiKey,'GET',null,false,false);
    if(response.status===200){
        await this.setPhotos(response.data);
        await this.setState({data: response.data})
    }else{
      notificationPop('error', notificationContent.FAILED, 'Try again later')
    }
    this.setState({loading:false});
  };
  goToJobSearch = () => {
    this.props.history.push(
        '/job-search',
        { company: this.state.data.Name}
    )
}

  componentDidMount() {
    const ID = new URLSearchParams(window.location.search)?.get('id') || ''
        if (ID) {
            this.setState({buyerId:ID},()=>{ this.getEmplyorData()})
        }
    
  }

  render() {
    document.title = 'Employer Details - ' + WEBSITE_NAME
    return (
      <div className="push-content-down-header">
        
        <Spin spinning={this.state.loading}>
          {this.state.data?(
            <>
              {/* <div className="blog-intro-bg">
                <Image
                  preview={false}
                  src={require('../../assets/' + FOLDER_NAME + '/curved-bg.png')}
                />
              </div> */}
              <div className="curved-bg"></div>
              <div className="sm-margins intro-card-position mx-1">
                <div className="container">
                <div className="intro-card rounded-2xl-card mb-5">
                  <Card>
                    <Row gutter={[24, 16]}>
                      <Col xs={24} md={5}>
                        <div className="logo-container place-center bg-white m-auto">
                          <Image
                            className="p-2"
                            preview={false}
                            src={this.state.data?.LogoUrl}
                          />
                        </div>
                      </Col>
                      <Col xs={24} md={19}>
                        <Title level={3} className="text-white">{this.state.data && this.state.data.Name?this.state.data.Name:'-'}</Title>
                        <Row>
                          <Col xs={24} lg={18}>
                            <p className="text-white">{this.state.data?.CompanyTagLine}</p>
                          </Col>
                          <Col xs={24} lg={6}>
                            <div className="place-center h-full">
                              <Button htmlType="submit" type="primary" size="large" className="bg-green" onClick={this.goToJobSearch}>{content.ViewAllJobs}</Button>
                            </div>
                          </Col>
                        </Row>
                        </Col>
                    </Row>
                  </Card>
                </div>
                <Space wrap size={45} className="mb-4">
                  <Button type="text" className="px-0 occ-hover" href="#About">About</Button>
                  <Button type="text" className="px-0 occ-hover" href="#Why">Why join us</Button>
                  <Button type="text" className="px-0 occ-hover" onClick={this.goToJobSearch}>Jobs</Button>
                  <Button type="text" className="px-0 occ-hover" href="#Photos">Photos</Button>
                  <Button type="text" className="px-0 occ-hover" href="#Testimonials">Testimonials</Button>
                  <Button type="text" className="px-0 occ-hover" href="#Questions">Questions</Button>
                </Space>
                <section className="mb-4 relative">
                  <div id="About" class="scroll-to-offset"></div>
                  <Row gutter={[24, 24]}>
                    <Col xs={24} lg={16}>
                      <TitleNText title={"About the company"} description={this.state.data?.CompanyProfile} />
                    </Col>
                    <Col xs={24} lg={8}>
                      <div className="gray-card rounded-2xl-card">
                        <Card>
                          <div>
                            <Title level={5} className="color-blue">Company size</Title>
                          </div>
                          {this.state.data && this.state.data.CompanySize?this.state.data.CompanySize:"-"}
                          <hr className="border-dotted my-3" />
                          <div>
                            <Title level={5} className="color-blue">Founded</Title>
                          </div>
                          {this.state.data && this.state.data.Founded?this.state.data.Founded:"-"}
                          {/* <hr className="border-dotted my-3" />
                          <div>
                            <Title level={5} className="color-blue">Revenue</Title>
                          </div>
                          £75m to £365m (GBP) */}
                          <hr className="border-dotted my-3" />
                          <div>
                            <Title level={5} className="color-blue">Headquarters</Title>
                          </div>
                          {this.state.data && this.state.data.Headquarters?this.state.data.Headquarters:"-"}
                          <hr className="border-dotted my-3" />
                          <div>
                            <Title level={5} className="color-blue">CEO</Title>
                          </div>
                          {this.state.data && this.state.data.Ceo?this.state.data.Ceo:'-'}
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </section>
                <section className="mb-4 relative">
                  <div id="Why" class="scroll-to-offset"></div>
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <TitleNText title={"Why join us"} description={this.state.data && this.state.data.WhyWorkHere?this.state.data.WhyWorkHere:"-"} />
                    </Col>
                    {this.state.data?.CompanyVideo?(
                      <Col span={24}>
                        <div className="video-container">
                          <iframe src={this.state.data?.CompanyVideo} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                          {/* <div className="video-description p-5">
                            <Title level={2} className="text-white">Employer Brand Video</Title>
                            <p className="text-white">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas egestas nunc et dolor posuere gravida. Nullam ullamcorper, felis sed facilisis molestie,
                            </p>
                          </div> */}
                        </div>
                      </Col>
                    ):null}
                    <Col span={24}>
                      <JobSearchBox horizontal={true}/>
                    </Col>
                  </Row>
                </section>
                <section className="mb-5 relative">
                  <div id="Photos" class="scroll-to-offset"></div>
                  <Title level={3} className="color-blue">Photos</Title>
                  <div className="curved-slider multi-img">
                    <LeftCircleFilled onClick={this.previous} className="color-blue text-4xl" />
                    <Carousel
                      slidesPerRow={3}
                      dots={false}
                      ref={node => (this.carousel = node)}
                    >
                      {this.state.companyPhotos?.map((item,index)=>{
                          return(
                              <div key={index}>
                                  <div className="slide">
                                      <Image
                                      preview={false}
                                      src={item}
                                      />
                                  </div>
                              </div>
                          )
                      })}
                    </Carousel>
                    <RightCircleFilled onClick={this.next} className="color-blue text-4xl" />
                  </div>
                  <div className="curved-slider xs-slider">
                    <LeftCircleFilled onClick={this.previous} className="color-blue text-4xl" />
                    <Carousel
                      dots={false}
                      ref={node => (this.carousel = node)}
                    >
                      {this.state.companyPhotos?.map((item,index)=>{
                          return(
                              <div key={index}>
                                  <div className="slide">
                                      <Image
                                      preview={false}
                                      src={item}
                                      />
                                  </div>
                              </div>
                          )
                      })}
                    </Carousel>
                    <RightCircleFilled onClick={this.next} className="color-blue text-4xl" />
                  </div>
                </section>
                <section className="mb-4 relative">
                <div id="Testimonials" class="scroll-to-offset"></div>
                  <Title level={3} className="color-blue">Staff testimonials</Title>
                  <Row gutter={[24, 24]}>
                    {this.state.data && this.state.data.StaffTestimonial1?(
                      <Col xs={24} md={12} lg={8} className="rounded-2xl-card testimonials-card">
                        <Card cover={this.state.data && this.state.data.StaffTestimonial1Photo?<img alt="Carejobs testimonials" src={this.state.data.StaffTestimonial1Photo} />:null}>
                          <Text strong className="color-blue text-base">{this.state.data?.StaffTestimonial1}</Text>
                        </Card>
                      </Col>
                    ):null}
                    {this.state.data && this.state.data.StaffTestimonial2?(
                    <Col xs={24} md={12} lg={8} className="rounded-2xl-card testimonials-card">
                      <Card cover={this.state.data && this.state.data.StaffTestimonial2Photo?<img alt="Carejobs testimonials" src={this.state.data.StaffTestimonial2Photo} />:null}>
                        <Text strong className="color-blue text-base">{this.state.data?.StaffTestimonial2}</Text>
                      </Card>
                    </Col>
                    ):null}
                    {this.state.data && this.state.data.StaffTestimonial3?(
                    <Col xs={24} md={12} lg={8} className="rounded-2xl-card testimonials-card">
                      <Card cover={this.state.data && this.state.data.StaffTestimonial3Photo?<img alt="Carejobs testimonials" src={this.state.data.StaffTestimonial3Photo} />:null}>
                        <Text strong className="color-blue text-base">{this.state.data?.StaffTestimonial3}</Text>
                      </Card>
                    </Col>
                    ):null}
                    {/* Staff Stories */}
                    {this.state.data?.StaffVideo1 || this.state.data?.StaffVideo2?(
                      <>
                        <Col span={24} className="mb-0 mt-4">
                          <Title level={3} className="color-blue mb-0">Staff Stories</Title>
                        </Col>
                        <Col xs={24} md={24} lg={12} className="rounded-2xl-card testimonials-card">
                          <div className="video-container">
                            <iframe src={this.state.data?.StaffVideo1} title="YouTube video player" className="h-story" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            {/* <div className="video-description p-4">
                              <div>
                                <Text strong className="text-base text-white">Dean Malpass</Text>
                              </div>
                              <Text italic className="text-base text-white">Support Worker</Text>
                            </div> */}
                          </div>
                        </Col>
                        <Col xs={24} md={24} lg={12} className="rounded-2xl-card testimonials-card">
                          <div className="video-container">
                            <iframe src={this.state.data?.StaffVideo2} title="YouTube video player" className="h-story" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            {/* <div className="video-description p-4">
                              <div>
                                <Text strong className="text-base text-white">Dean Malpass</Text>
                              </div>
                              <Text italic className="text-base text-white">Support Worker</Text>
                            </div> */}
                          </div>
                        </Col>
                      </>
                    ):null }
                    {/* Our Values */}
                    {this.state.data?.OurValues || this.state.ourValues?(
                      <>
                        <Col span={24} className="mb-0 mt-4">
                          <Title level={3} className="color-blue">Our Values</Title>
                          {this.state.data?.OurValues?(
                            <Text className="text-base">
                              {this.state.data?.OurValues}
                            </Text>
                            ):null}
                        </Col>
                        {this.state.ourValues?.map((valueItem,index)=>{
                          return(<Col xs={24} md={12} lg={8} key={index}>
                            <div className="gray-card rounded-2xl-card">
                              <Card>
                                <div>
                                  <Title level={5} className="color-blue">{valueItem.title}</Title>
                                </div>
                                {valueItem.description}
                              </Card>
                            </div>
                          </Col>
                          );
                        })}
                    </>
                    ):null}
                    {/* Benefits */}
                    {this.state.benefits.length>0?(
                      <>
                        <Col span={24} className="mb-0 mt-4">
                          <Title level={3} className="color-blue">Benefits</Title>
                        </Col>
                        {this.state.benefits?.map((valueItem,index)=>{
                            return(<Col xs={24} md={12} lg={8} key={index}>
                              <div className="gray-card rounded-2xl-card">
                                <Card>
                                  <div>
                                    <Title level={5} className="color-blue">{valueItem.title}</Title>
                                  </div>
                                  {valueItem.description}
                                </Card>
                              </div>
                            </Col>
                            );
                          })}
                      </>
                    ):null}
                    {/* //here */}
                    {this.state.data?.CareerDevelopment?(
                      <Col span={24}>
                        <TitleNText title={"Career Development"} description={this.state.data?.CareerDevelopment} />
                      </Col>
                    ):null}
                  </Row>
                </section>
                <section className="mb-4 relative">
                <div id="Questions" class="scroll-to-offset"></div>
                  <Title level={3} className="color-blue">Questions</Title>
                  <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                    expandIconPosition="end"
                    className="curved-collapse"
                  >
                    {this.state.questionAnswers?.map((questionitem,index)=>{
                      return(<Panel header={questionitem.question} key={index}>
                          <p className="text-base">{questionitem.ans}</p>
                        </Panel>);
                    })}
                  </Collapse>
                </section>
                </div>
              </div>
            </>
          ):null}
        </Spin>
      </div>
    );
  }
}
export default EmployerDetails;
