import React, { Component } from 'react'
import { Form, Button, Input, Spin, notification, Checkbox, Col, Row, Card, Typography } from 'antd'
import { apiCall } from '../../utils/http'
import { FOLDER_NAME, WEBSITE_NAME } from "../../utils/process";
import { notificationContent } from '../../constants/notifications'
import { content, getOS } from '../../constants/content'
import { cookie_list, createCookie, getCookie } from '../../utils/cookie'
import moment from 'moment'
import OTPModal from '../../components/modal'
import { googleAnalyticsRegister, facebookAnalyticsRegister } from '../../utils/events'
import SearchJobDataHandling from '../../utils/SearchJobDataHandling';

const { Text, Title } = Typography

let os = getOS()

//NOTIFICATION POPUP
function notificationPop(header, message) {
  notification.destroy()
  notification.error({
    message: header,
    description: message
  })
}
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loader: false,
      showModal: false,

      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      agreement: false,

      jobId: '',
    }
  }
  //CDM
  formRef = React.createRef();
  componentDidMount() {
    //check if email is passed through the url
    if(this.props?.location?.state?.email){
      this.formRef.current.setFieldsValue({
        email:this.props?.location?.state?.email,
        agreement:true
      });
    }
    //IF USER IS LOGGED IN REDIRECT TO HOMEPAGE
    if (getCookie(cookie_list.TOKEN)) {
      window.location.href = '/'
    }
    //CHECK FOR JOB ID
    if(this.props?.location?.state?.jobID) {
      this.setState({ jobId: this.props?.location?.state?.jobID })
    }
  }
  //GET USER TOKEN
  fetchUserToken = async () => {
    // eslint-disable-next-line no-undef
    let apiKey = process.env.REACT_APP_API_KEY

    let form_data = {
      Email: this.state.email,
      ApiKey: apiKey
    }

    await apiCall('users/otp/request', 'POST', form_data).then((res) => {
      if (res?.status === 200) {
        this.setState({ showModal: true })
      } else {
        notificationPop(notificationContent.FAILED, res?.data?.message)
      }
      this.setState({ loader: false })
    })
  }
  //REGISTER NEW USER
  registerUser = async () => {
    // eslint-disable-next-line no-undef
    let apiKey = process.env.REACT_APP_API_KEY

    let form_data = {
      Name: `${this.state.firstName} ${this.state.lastName}`,
      Email: this.state.email,
      Source: os,
      OperatingSystem: 'windows',
      SendDailyEmails: this.state.agreement,
      Telephone: this.state.phoneNumber,
      SalaryRange: 'From1To10000',
      UtmCampaign: '',
      UtmMedium: '',
      UtmSource: '',
      ApiKey: apiKey
    }

    await apiCall('users', 'POST', form_data).then((res) => {
      if (res?.status === 200) {
        this.fetchUserToken()
        //SEND REGISTER EVENT TO GOOGLE TAB MANAGER
        googleAnalyticsRegister()
        facebookAnalyticsRegister()
      } else {
        notificationPop(notificationContent.FAILED, res?.data?.message)
      }
      this.setState({ loader: false })
    })
  }
  //GET TOKEN RESPONSE AND SET COOKIES
  modalResponse = async (response) => {
    if (response?.status === 200) {
      //SET COOKIES
      createCookie(cookie_list.TOKEN, response?.data?.access_token, 4320, '/')
      createCookie(cookie_list.EMAIL, this.state.email, 4320, '/')
      createCookie(cookie_list.FIRSTNAME, response?.data?.FirstName, 4320, '/')
      createCookie(cookie_list.EXPIRY, moment().add(180, 'days').format('YYYY-MM-DD HH:mm:ss'), 4320, '/')
      createCookie(cookie_list.DISTANCE, '10', 4320, '/')
      createCookie(cookie_list.CV, '', 4320, '/')
      createCookie(cookie_list.POSTCODE, '', 4320, '/')
      let isRestoredLocation = await SearchJobDataHandling.restoreLocationData();
      if(isRestoredLocation){
        createCookie(cookie_list.LOCATION, getCookie(cookie_list.LOCATION) || '', 4320, '/')
        createCookie(cookie_list.LONGITUDE, getCookie(cookie_list.LONGITUDE) || '', 4320, '/')
        createCookie(cookie_list.LATITUDE, getCookie(cookie_list.LATITUDE) || '', 4320, '/')
        createCookie(cookie_list.LOCATIONTYPE, getCookie(cookie_list.LOCATIONTYPE) || '', 4320, '/')
        createCookie(cookie_list.COUNTRY, getCookie(cookie_list.COUNTRY) || '', 4320, '/')
      
        //CHECK IF OCCUPATION COOKIES ARE PRESENT AND MAKE A API CALL TO SAVE THEM
        let isoccupationRestored=await SearchJobDataHandling.checkUserData();
        //CLOSE MODAL AND REDIRECT TO HOME
        if(isoccupationRestored){
          this.setState({ showModal: false }, () => {
            if(this.state.jobId) {
              window.location.href = `/job-detail?id=${this.state.jobId}`
            } else {
              window.location.href = '/'
            }
          })
        }
      }
    } else {
      notificationPop(notificationContent.FAILED, response?.data?.message)
    }
  }
  //RENDER
  render() {
    document.title = 'Sign Up - ' + WEBSITE_NAME
    return (
      <Spin spinning={this.state.loader}>
        {this.state.showModal && <OTPModal email={this.state.email} response={this.modalResponse} />}
        <div style={{ backgroundImage: `url(${require('../../assets/' + FOLDER_NAME + '/signup-bg.png')})` }} className='titlemargin bg-position'>
          <div className='vc_column-inner container'>
            <Title className='mb-0'><span className='accent'>{content.SignIn} / {content.Register}</span></Title>
            <Title level={3} className={FOLDER_NAME === 'nursesjobs' ? 'my-0' : 'text-white my-0'}>{content.SignInSubtitle}</Title>
          </div>
        </div>
        <div className='sm-margins container'>
          <Row gutter={ [{ xs: 0, sm: 16 }, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {/* LOGIN FORM */}
              <Card
                className='crd-header-style'
                bordered={false}
                title={content.SignIn}
              >
                <Form onFinish={(values) => this.setState({
                  loader: true,
                  email: values?.username
                }, () => this.fetchUserToken())}
                  layout="vertical"
                  requiredMark={false}
                >
                  <Form.Item
                    name='username'
                    label={content.Email}
                    rules={[
                      {
                        required: true,
                        message: 'Enter your Email'
                      },
                      {
                        type: 'email',
                        message: 'Invalid email'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name='submit'>
                    <Button htmlType='submit' type="primary" size="large">{content.Login}</Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {/* REGISTER FORM */}
              <Card
                className='crd-header-style'
                bordered={false}
                title={content.CreateAccount}
              >
                <Form onFinish={(values) => this.setState({
                  loader: true,
                  firstName: values?.firstname,
                  lastName: values?.lastname,
                  email: values?.email,
                  phoneNumber: values?.phonenumber,
                  agreement: values?.agreement || false,
                }, () => this.registerUser())}
                  layout="vertical"
                  requiredMark={false}
                  ref={this.formRef}
                >
                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        className='mb-0'
                        label="Name"
                        name='firstname'
                        rules={[{ required: true, message: 'First name is required' }]}
                      >
                        <Input />
                      </Form.Item>
                      <div className='mb-2'><Text type="secondary">First name</Text></div>
                    </Col>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        label="lastname"
                        name='lastname'
                        rules={[{ required: true, message: 'Last name is required' }]}
                        className="label-hidden mb-0"
                      >
                        <Input />
                      </Form.Item>
                      <div className='mb-2'><Text type="secondary">Last name</Text></div>
                    </Col>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        name='email'
                        label={content.Email}
                        rules={[
                          {
                            required: true,
                            message: 'Enter your Email'
                          },
                          {
                            type: 'email',
                            message: 'Invalid email'
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} lg={12}>
                      <Form.Item
                        name='phonenumber'
                        label={content.Phone}
                        rules={[
                          {
                            required: true,
                            message: 'Enter your Phone number',
                          },
                          {
                            pattern: '^[- +()0-9]{10,}$',
                            message: 'Invalid Phone Number'
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item name='agreement' valuePropName="checked">
                    <Checkbox>{this.props?.location?.state?.email?content.AgreeBlogNotifications:content.AgreeNotifications}</Checkbox>
                  </Form.Item>
                  <Form.Item name='submit'>
                    <Button htmlType='submit' type="primary" size="large">{content.Register}</Button>
                  </Form.Item>
                </Form>
                <i>{content.TermsOfUse}</i>
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    )
  }
}
export default Login
