import React, { Component } from "react";
import {Typography} from "antd";
import { WEBSITE_NAME } from "../utils/process";
const { Text, Title, Paragraph} = Typography;
class TitleNText extends Component {
    constructor() {
      super();
      this.state = {
        title:this.props?.title,
        description:this.props?.description,
      };
    }
  
    componentDidMount() {
        this.setState({title:this.props?.title, description:this.props?.description});
    }
  
    render() {
      return (
        <div>
          <Title level={3} className="color-blue">{this.state.title?this.state.title:"-"}</Title>
          <Paragraph>{this.props && this.props.description?this.props.description:"-"}</Paragraph>
        </div>
      );
    }
  }
  export default TitleNText;