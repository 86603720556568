import React, { Component } from 'react'
import { Layout, BackTop } from 'antd'
import { ArrowUpOutlined } from '@ant-design/icons'
import { LayoutHeader, LayoutFooter } from '../components'
import EmployerLayoutFooter from './employerFooter'
const { Content } = Layout
class WebLayout extends Component {
  componentDidMount(){
    console.log("props",this.props)
  }
  render() {
    return (
      <>
        <BackTop><ArrowUpOutlined /></BackTop>
        <LayoutHeader />
        <Content>
          <div>{React.cloneElement(this.props.children, { ...this.props })}</div>
        </Content>
        {this.props && this.props.match?.path==='/employer-details' || this.props.match?.path==='/article'|| this.props.match?.path==='/article-detail'?
        <EmployerLayoutFooter {...this.props}/>:<LayoutFooter />}
      </>
    )
  }
}
export default WebLayout
