import React, { Component } from 'react'
import { Form, Row, Col, Select, /*AutoComplete,*/ Button, Spin } from 'antd'
import { SUPPLIER_ID } from "../../../utils/process";
import { content } from '../../../constants/content'
import { apiCall } from '../../../utils/http'
import { getCookie, cookie_list, createCookie } from '../../../utils/cookie'
import moment from "moment";
import SearchJobDataHandling from '../../../utils/SearchJobDataHandling';
import CommonFunctionalities from '../../../utils/CommonFunctionalities';

let apiKey = process.env.REACT_APP_API_KEY
const { Option } = Select
const dateFormat = 'YYYY-MM-DD'
class SearchCriteria extends Component {
  constructor() {
    super()
    this.state = {
      previousLocationsList: [],
      locationList: [],
      occupationsList: [],

      occupations: [],
      options: [],
      prevLocation: '',
      location: null,
      longitude: '',
      latitude: '',
      token: getCookie(cookie_list.TOKEN),
      redirect: false,
      loader: true,
    }
    this.formRef = React.createRef()
  }

  componentDidMount() {
    this.fetchPreviousLocations()
    this.fetchCurrentLocation()
    this.fetchOccupationList()
  }

  fetchPreviousLocations = async () => {
    let url = `users/previouslocations?apiKey=${apiKey}`
    await apiCall(url, 'GET', '', false, true).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.length > 0) {
          //REMOVE DUPLICATES
          let data = res?.data.filter((ele, ind) => ind === res?.data.findIndex(elem => elem.LocationName === ele.LocationName))
          if (data?.length > 0) {
            this.setState({
              prevLocation: data[0].LocationName,
              previousLocationsList: data
            }, () => {
              this.formRef.current.setFieldsValue({ prevLocation: data[0].LocationName })
            })
          }
        }
      }
    })
    this.setState({ loader: false })
  }

  fetchCurrentLocation = async () => {
    let url = `users/currentlocation?apiKey=${apiKey}`
    await apiCall(url, 'GET', '', false, true).then((res) => {
      if (res) {
        this.setState({
          occupations: getCookie(cookie_list.OCCUPATIONS) ? getCookie(cookie_list.OCCUPATIONS)?.split(',') : [],
          location: res?.status === 200 && res?.data?.LocationName ? res?.data?.LocationName : getCookie(cookie_list.LOCATION),
          longitude: res?.status === 200 && res?.data?.Longitude ? res?.data?.Longitude : getCookie(cookie_list.LONGITUDE),
          latitude: res?.status === 200 && res?.data?.Latitude ? res?.data?.Latitude : getCookie(cookie_list.LATITUDE),
          distance: getCookie(cookie_list.DISTANCE)
        }, () => {
          this.formRef.current.setFieldsValue({
            occupations: getCookie(cookie_list.OCCUPATIONS) ? getCookie(cookie_list.OCCUPATIONS)?.split(',') : [],
            location: res?.status === 200 && res?.data?.LocationName ? res?.data?.LocationName : getCookie(cookie_list.LOCATION),
          })
        })
      }
    })
    this.setState({ loader: false })
  }

  fetchLocationList = async (e) => {
    if(e) {
      let locationTyped=e.replace(/\s/g, "");
      let url = `CityAddresses?apiKey=${apiKey}&countryCode=GB&city=` + locationTyped
      await apiCall(url, 'GET', '', true).then((res) => {
        if (res?.status === 200) {
          let data = []
          if (res?.data?.length > 0) {
            data = res?.data?.map((item,index) => { return { value: CommonFunctionalities.SearchLocationOptionValue(item), key: index  } })
            this.setState({
              locationList: res?.data,
              options: data,
            })
          }
        }
      })
    }
  }

  fetchOccupationList = async () => {
    await apiCall(`occupations/supplier/${SUPPLIER_ID}?apiKey=${apiKey}`, 'GET').then((res) => {
      if (res?.status === 200 && res?.data?.length > 0) {
        this.setState({ occupationsList: res.data })
      }
    }).catch(() => {
      //DO NOTHING
    })
    this.setState({ loader: false })
  }

  validateFields = () => {
    this.formRef.current.validateFields().then(() => {
      this.setState({ redirect: true }, () => this.saveCurrentLocation())
    }).catch(() => {
      //DO NOTHING
      this.setState({ loader: false })
    })
  }

  saveCurrentLocation = async () => {
    let url = `users/location?apiKey=${apiKey}`
    let formdata = {}
    let location = null
    let longitude = ''
    let latitude = ''
    if (this.state.locationList?.length > 0) {
      location = this.state.locationList?.find((loc) => `${loc.City}, ${loc.County}` === this.state.location)
      if (location) {
        formdata.MaxDistanceKm = 0
        formdata.LocationName = `${location.City}, ${location.County}`
        formdata.Country = location.Country
        formdata.Longitude = location.Longitude
        formdata.Latitude = location.Latitude
        formdata.LocationType = location.Type
        formdata.CreatedOn = moment().format(dateFormat)
        formdata.ApiKey = apiKey

        longitude = location.Longitude
        latitude = location.Latitude
      }
    }

    await apiCall(url, 'POST', formdata, false, true).then(async() => {
      //TO SET OCCUPATION IN COOKIE/API
      await SearchJobDataHandling.searchData(this.state.occupations)
      //SET COOKIES
      createCookie(cookie_list.LOCATION, this.state.location, 4320, '/')
      createCookie(cookie_list.DISTANCE, this.state.distance, 4320, '/')
      createCookie(cookie_list.LONGITUDE, longitude || this.state.longitude, 4320, '/')
      createCookie(cookie_list.LATITUDE, latitude || this.state.latitude, 4320, '/')
      createCookie(cookie_list.LOCATIONTYPE, location.Type || '', 4320, '/')
      createCookie(cookie_list.COUNTRY, location.Country || '', 4320, '/')
      this.setState({ loader: false })
      //REDIRECT TO JOB LIST
      if (this.state.redirect) {
        window.location.href = '/job-search'
      }
    }).catch(() => {
      this.setState({ loader: false })
    })
  }

  savePreviousLocation = async () => {
    let url = `users/location?apiKey=${apiKey}`
    let formdata = {}
    let location = null
    let longitude = ''
    let latitude = ''
    if (this.state.prevLocation) {
      location = this.state.previousLocationsList?.find((loc) => loc.LocationName === this.state.prevLocation)
      if (location) {
        formdata.MaxDistanceKm = 0
        formdata.LocationName = location.LocationName
        formdata.Country = location.Country
        formdata.Longitude = location.Longitude
        formdata.Latitude = location.Latitude
        formdata.LocationType = location.Type
        formdata.CreatedOn = location.CreatedOn
        formdata.ApiKey = apiKey

        longitude = location.Longitude
        latitude = location.Latitude
      }
    }

    await apiCall(url, 'POST', formdata, false, true).then(async() => {
      //SET OCCUPATION IN COOKIE/API
      await SearchJobDataHandling.searchData(this.state.occupations)
      this.setState({ location: this.state.prevLocation }, () => {
        //SET CURRENT LOCATION AS VALUE OF PREVIOUS LOCATION
        this.formRef.current.setFieldsValue({ location: this.state.prevLocation })
        //SET COOKIES
        createCookie(cookie_list.LOCATION, this.state.prevLocation, 4320, '/')
        createCookie(cookie_list.LONGITUDE, longitude, 4320, '/')
        createCookie(cookie_list.LATITUDE, latitude, 4320, '/')
        createCookie(cookie_list.LOCATIONTYPE, location.Type || '', 4320, '/')
        createCookie(cookie_list.COUNTRY, location.Country || '', 4320, '/')
        this.setState({ loader: false })
        //REDIRECT TO JOB LIST
        // window.location.href = '/job-search'
      })
    }).catch(() => {
      this.setState({ loader: false })
    })
  }

  render() {
    return (
      <Spin spinning={this.state.loader}>
        <Form
          layout="vertical"
          requiredMark={false}
          ref={this.formRef}
        >
          <Row gutter={[16, 0]}>
            <Col xs={24} sm={24} lg={12}>
              <Form.Item
                label={content.Occupations}
                name='occupations'
                rules={[{ required: true, message: "Select closest Occupation from the given suggestions" }]}>
                <Select
                  className='custom-multi-select'
                  allowClear
                  mode="multiple"
                  placeholder="Select occupations"
                  onChange={(e) => this.setState({ occupations: e })}
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                >
                  {this.state.occupationsList?.map((item) => (
                    <Option key={item.Id}>{item.Occupation}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={19} lg={19}>
                  <Form.Item
                    label={content.CurrentLocation}
                    name='location'
                    rules={[
                      {
                        required: true,
                        message: "Enter Preferred Job Location",
                      },
                    ]}
                    className="search-form"
                  >
                    {/* <AutoComplete
                      options={this.state.options}
                      placeholder="eg. London"
                      onSearch={this.fetchLocationList}
                      onChange={(e) => this.setState({ location: e })}
                      value={this.state.location}
                    /> */}
                    <Select
                      showSearch
                      options={this.state.options}
                      filterOption={(input, option) =>  option.value.toLowerCase().includes(input.toLowerCase())}
                      value={this.state.location}
                      placeholder={content.locationPlaceholder}
                      onChange={(e) => this.setState({ location: e })}
                      onSearch={this.fetchLocationList}
                      notFoundContent={null}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={5} lg={5}>
                  <Form.Item label="Update" className='search-form label-hidden'>
                    <Button onClick={() => this.setState({ loader: true }, () => this.saveCurrentLocation())} size='large' className='outline'>Update</Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={19} lg={19}>
                  <Form.Item
                    label={content.PreviousLocations}
                    name='prevLocation'
                    className='search-form'
                  >
                    <Select
                      value={this.state.prevLocation}
                      onChange={(e) => this.setState({ prevLocation: e, })}
                    >
                      {this.state.previousLocationsList?.map((item) => (
                        <Option key={item.Id} value={item.LocationName}>{item.LocationName}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={5} lg={5}>
                  <Form.Item label="Use" className='search-form label-hidden'>
                    <Button 
                      disabled={this.state.previousLocationsList?.length === 0} 
                      onClick={() =>this.setState({ loader: true }, () => this.savePreviousLocation())} 
                      size='large' 
                      className='outline'>Use</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Form.Item name="submit">
            <Button onClick={() => this.setState({ loader: true }, () => this.validateFields())} type="primary" size="large">
              {content.FindJobs}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    )
  }
}

export default SearchCriteria