import React, { Component } from 'react'
import CVList from '../../cvlist'

class CV extends Component {
  state = {  } 

  componentDidMount() {
    console.log('CVS')
  }

  render() { 
    return (
      <CVList showCheckbox={false}/>
    )
  }
}
 
export default CV