import React, { Component } from 'react'
import { apiCall } from '../../../utils/http'
import { Divider, Typography, Space, Row, Col } from 'antd'
let apiKey = process.env.REACT_APP_API_KEY
const { Text, Title } = Typography;
class Applications extends Component {

  constructor() {
    super()
    this.state = {
      applicationList: [
        // {
        //   "JobId": 0,
        //   "JobTitle": "string",
        //   "JobCity": "string",
        //   "JobState": "string",
        //   "JobCountryCode": "string",
        //   "Url": "string",
        //   "StatusId": 0,
        //   "CreatedOn": "1970-06-15",
        //   "Source": 0,
        //   "ApplicationStatusId": 0,
        //   "ApplicationStatusText": "string"
        // },
        // {
        //   "JobId": 1,
        //   "JobTitle": "string",
        //   "JobCity": "string",
        //   "JobState": "string",
        //   "JobCountryCode": "string",
        //   "Url": "string",
        //   "StatusId": 0,
        //   "CreatedOn": "1970-06-15",
        //   "Source": 0,
        //   "ApplicationStatusId": 0,
        //   "ApplicationStatusText": "string"
        // }
      ],
      loader: false
    }
  }

  componentDidMount() {
    this.fetchApplicationList()
  }

  fetchApplicationList = async () => {
    await apiCall(`jobshortlists?apiKey=${apiKey}&source=Ios&pageNumber=1&pageSize=10`, 'GET', '', false, true).then((res) => {
      if (res?.status === 200 && res?.data?.length > 0) {
        this.setState({ applicationList: res.data })
      }
    }).catch(() => {
      //DO NOTHING
    })
    this.setState({ loader: false })
  }

  render() {
    return (
      <>
        {this.state.applicationList?.map((item) => (
          <>
            <Space direction='vertical' size={'large'} className='w-100'>
              <div>
                <Title className='mb-0' level={3}><span className='accent'>{item.JobTitle}</span></Title>
                <div>
                  <Text type="secondary" strong className='sm-text'>{item.CreatedOn}</Text>
                </div>
              </div>
              <Row>
                <Col xs={24} lg={8}><Text type="secondary" strong className='sm-text'>Country:</Text> <Text strong className='sm-text'>{item.JobCountryCode}</Text></Col>
                <Col xs={24} lg={8}><Text type="secondary" strong className='sm-text'>State:</Text> <Text strong className='sm-text'>{item.JobState}</Text></Col>
                <Col xs={24} lg={8}><Text type="secondary" strong className='sm-text'>City:</Text> <Text strong className='sm-text'>{item.JobCity}</Text></Col>
              </Row>
            </Space>
            <Divider />
          </>
        ))}
      </>
    )
  }
}

export default Applications