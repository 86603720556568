import React, { Component } from 'react'
import { Checkbox, Spin, AutoComplete, Button, Select, List, Form, Image, Col, Row, Card, Typography, Space, notification, Collapse } from 'antd'
import { cookie_list, createCookie, getCookie } from "../../utils/cookie";
import { apiCall } from '../../utils/http'
import { FOLDER_NAME, SUPPLIER_ID, WEBSITE_NAME } from "../../utils/process";
import { content, getOS } from '../../constants/content';
import { notificationContent } from '../../constants/notifications';
import { FilterOutlined } from "@ant-design/icons";
import "../jobsearch/index.css";
import { googleAnalyticsApplyOnSite } from '../../utils/events'
import SearchJobDataHandling from '../../utils/SearchJobDataHandling';
import moment from "moment";
import CommonFunctionalities from '../../utils/CommonFunctionalities';

const { Option } = Select;
const { Text, Title } = Typography;
const { Panel } = Collapse;
let apiKey = process.env.REACT_APP_API_KEY
const regex = /(<([^>]+)>)/ig

let os = getOS()

//NOTIFICATION POPUP
function notificationPop(type, header, message) {
  notification.destroy()
  notification[type]({
    message: header,
    description: message
  })
}
const urlParams = new URLSearchParams(window.location.search);
class JobSearch extends Component {
  constructor() {
    super()
    this.state = {
      loader: false,
      listLoader: false,
      occupationList: [],
      originalOccupationList: [],
      companyList: [],
      selectedOccupations: getCookie(cookie_list.OCCUPATIONS) || [],
      selectedCompany: 'Show all',
      jobList: [],
      locationList: [],
      jobId: '',
      selectedJobs: [],
      locationError: '',
      pageNumber: 1,
      similarJobs: false,
      location: getCookie(cookie_list.LOCATION) ? getCookie(cookie_list.LOCATION) : '',
      distance: getCookie(cookie_list.DISTANCE) ? getCookie(cookie_list.DISTANCE) : '5',
      latitude: getCookie(cookie_list.LATITUDE) ? getCookie(cookie_list.LATITUDE) : '',
      longitude: getCookie(cookie_list.LONGITUDE) ? getCookie(cookie_list.LONGITUDE) : '',
      apicallText: '',
      pageSize: 50,
      totalJobs: 0,
      isCompanySearch: false,
      isAutoCompleteChange: false,
    }
  }
  formRef = React.createRef();

  //Gets occupation and location from cookies
  componentDidMount() {

    const locationParam = urlParams.get('Location');
    const distanceParam = urlParams.get('distance');
    const occupationIdsParam = urlParams.get('occupationIds');

    if (locationParam) {
      this.fetchLocation(locationParam)
    }
    if (distanceParam) {
      createCookie(cookie_list.DISTANCE, distanceParam, 4320, '/')
      this.setState({
        distance: distanceParam
      })
    }

    const utm_campaign = new URLSearchParams(window.location.search)?.get('utm_campaign') || null
    if (utm_campaign) {
      createCookie(cookie_list.UTMCAMPAIGN, utm_campaign, 4320, '/');
    }
    if (occupationIdsParam) {
      console.log("occupationIdsParam", occupationIdsParam)
      let modifiedOccupationIdsParam = occupationIdsParam;
      modifiedOccupationIdsParam.split(',').map(item => parseInt(item));
      createCookie(cookie_list.OCCUPATIONS, occupationIdsParam, 4320, '/')
      this.setState({
        selectedOccupations: modifiedOccupationIdsParam
      })
    }

    window.scrollTo(0, 0);
  }

  fetchLocationList = async (e) => {
    let locationTyped = e.replace(/\s/g, "");
    let url = `CityAddresses?apiKey=${apiKey}&countryCode=GB&city=` + locationTyped
    await apiCall(url, 'GET', '', true).then((res) => {
      if (res?.status === 200) {
        let data = []
        if (res?.data?.length > 0) {
          data = res?.data?.map((item) => { return { value: CommonFunctionalities.SearchLocationOptionValue(item) } })
          this.setState({
            locationList: res?.data,
            options: data,
          })
          return res
        }
      }
    })
  }

  fetchLocation = async (e) => {
    if (e) {
      let locationTyped = e;
      locationTyped.replace(/\s/g, "").toLowerCase().split(',')[0].trim();
      let url = `CityAddresses?apiKey=${apiKey}&countryCode=GB&city=` + locationTyped
      await apiCall(url, 'GET', '', true).then((res) => {
        if (res?.status === 200) {
          if (res?.data?.length > 0) {
            const firstMatchingOption = res?.data[0];
            console.log("e", e)
            createCookie(cookie_list.LOCATION, e, 4320, '/')
            createCookie(cookie_list.LONGITUDE, firstMatchingOption.Longitude, 4320, '/')
            createCookie(cookie_list.LATITUDE, firstMatchingOption.Latitude, 4320, '/')
            createCookie(cookie_list.COUNTRY, firstMatchingOption.Country || '', 4320, '/')
            this.formRef.current.setFieldsValue({
              location: e
            });
            this.setState({
              location: e,
              latitude: firstMatchingOption.Latitude,
              longitude: firstMatchingOption.Longitude
            }, () => {
              console.log('Entered here');
              this.onPageLoad()
            })
          }
        }
      })
    }
  }

  onPageLoad = () => {
    let company = this.props?.location?.state?.company;
    if (company) {
      // this.setSelectedOccupations(company,content.Company)
      this.setState({ selectedCompany: company, isCompanySearch: true, location: "", distance: "5", latitude: "", longitude: "" }, () => {
        this.formRef.current.setFieldsValue({
          company: company
        });
      })
    }
    let currentPageNumber = this.props?.location?.state?.pageNumber;
    if (currentPageNumber) { this.setState({ pageNumber: this.props?.location?.state?.pageNumber }) }
    //CHECK FOR JOB ID
    if (this.props?.location?.state?.jobID) {
      this.setState({ jobId: this.props?.location?.state?.jobID }, () => {
        if (this.props?.location?.state?.similarJobs === true) {
          this.setState({ similarJobs: this.props?.location?.state?.similarJobs })
          this.fetchSimilarJobs()
        }
        else {
          this.onLoadFunction()
        }
      })
    }
    else {
      this.onLoadFunction()
    }
  }

  onLoadFunction = async () => {
    await this.fetchOccupationList()
    this.formRef.current.setFieldsValue({
      location: this.state.location,
      distance: this.state.distance
    });
    let values = {
      location: this.state.location,
      distance: this.state.distance
    }
    let convertedOccupations = [];
    if (!this.state.isCompanySearch) {
      let occupations = getCookie(cookie_list.OCCUPATIONS) ? getCookie(cookie_list.OCCUPATIONS)?.split(',') : []
      convertedOccupations = occupations?.map(function (item) {
        return parseInt(item, 10);
      });
    }
    this.setState({ selectedOccupations: convertedOccupations }, () => {
      this.submitFilter(values)
    })
  }

  //Fetches the list of Occupation
  fetchOccupationList = async () => {
    await apiCall('occupations/supplier/' + SUPPLIER_ID + '?apiKey=' + apiKey, 'GET').then((res) => {
      if (res?.status === 200) {
        let occs = res?.data?.map((item) => {
          if (item.Occupation === 'Care Assistant / Worker / Carer') { item.Occupation = 'Care Assistant / Carer' }
          item.value = item.Id
          item.label = item.Occupation
          return { ...item };
        });
        occs = occs.sort((a, b) => a.Occupation.localeCompare(b.Occupation));
        this.setState({ occupationList: occs, originalOccupationList: occs })
      }
      else {
        notificationPop('error', notificationContent.FAILED, res?.message)
      }
      this.setState({ loader: false })
    }).catch(() => {
      notificationPop('error', notificationContent.FAILED, 'Try again later')
      this.setState({ loader: false })
    })
  }

  fetchOccupationCount = async () => {
    this.setState({ loader: true });
    const OccupationList = this.state.originalOccupationList;
    let occupationURL = 'search/jobcountbyoccupations?';
    let companyURL = 'search/jobcountbycompany?';
    let url = 'apiKey=' + apiKey + '&cpa=true&cpac=true&cpc=true&countryCode=GB'

    if (this.state.latitude && this.state.longitude) {
      url += '&longitude=' + this.state.longitude + '&latitude=' + this.state.latitude;
    }
    if (this.state.distance) {
      url += '&distance=' + this.state.distance;
    }
    OccupationList.map((item) => {
      url += "&occupationIds=" + item.Id
    })
    let companynamesurl = this.state.selectedCompany && this.state.selectedCompany !== 'Show all' ? "&companyNames=" + this.state.selectedCompany : "";
    await apiCall(occupationURL + url + companynamesurl, 'GET').then((res) => {
      if (res?.status === 200) {
        let occs = [];
        OccupationList.forEach((item) => {
          const filterItem = res?.data?.filter((countItem) => countItem.OccupationId === item.Id);
          if (filterItem.length > 0) {
            // if(filterItem[0].Count>0){
            item.label = item.Occupation + ' (' + filterItem[0].Count + ')';
            // }
          } else {
            item.label = item.Occupation + ' (0)';
          }
          occs.push(item);
        })
        // let occs = OccupationList.map((item) => {
        //   const filterItem=res?.data?.filter((countItem)=>countItem.OccupationId == item.Id);
        //   if(filterItem.length>0){
        //     item.label = item.Occupation + ' (' + filterItem[0].Count + ')';
        //   }else{
        //     item.label = item.Occupation + ' (0)';
        //   }
        //   return item;
        // })
        if (!occs.length > 0) {
          this.setState({ selectedOccupations: [] })
        }
        this.setState({ occupationList: occs })
      }
      else {
        notificationPop('error', notificationContent.FAILED, res?.message)
      }
      // this.setState({ loader: false })
    }).catch(() => {
      notificationPop('error', notificationContent.FAILED, 'Try again later')
      // this.setState({ loader: false })
    })
    //get company count
    await apiCall(companyURL + url, 'GET').then((res) => {
      if (res?.status === 200) {
        if (res?.data.length > 0) {
          let coList = res.data.map((item) => {
            return { label: item.Name + " (" + item.Count + ")", value: item.Name }
          })
          this.setState({ companyList: [{ label: 'Show all', value: 'Show all' }, ...coList] }, () => {
            const company = urlParams.get('company');
            if (company) {
              this.formRef.current.setFieldsValue({
                company: company
              });
            }
          });

        } else {
          this.setState({ companyList: [] })
        }
      } else {
        this.setState({ companyList: [] })
        notificationPop('error', notificationContent.FAILED, res?.message)
      }
      this.setState({ loader: false })
    }).catch(() => {
      this.setState({ companyList: [] })
      notificationPop('error', notificationContent.FAILED, 'Try again later')
      this.setState({ loader: false })
    })
  }

  updateUrlParam = (paramName, paramValue) => {
    // Get the current URL
    let url = new URL(window.location.href);

    // Create a URLSearchParams object from the current URL
    let searchParams = url.searchParams;

    // Update or add the specified parameter
    searchParams.set(paramName, paramValue);

    // Create a new URL with the updated parameters
    let newUrl = `${url.origin}${url.pathname}?${searchParams.toString()}`;

    // Update the URL
    window.history.replaceState({}, '', newUrl);
  };

  setSelectedOccupations = (e, changedField = content.Occupations) => {
    let values = {
      location: this.state.location ? this.state.location : '', //getCookie(cookie_list.LOCATION) ? getCookie(cookie_list.LOCATION) : '',
      distance: this.state.distance ? this.state.distance : '5' //getCookie(cookie_list.DISTANCE) ? getCookie(cookie_list.DISTANCE) : ''
    }
    if (changedField === content.Company) {
      const distanceParam = urlParams.get('company');
      if (distanceParam) {
        this.updateUrlParam('company', e);
      }
      this.setState({ selectedCompany: e, pageNumber: 1 }, () => {
        this.submitFilter(values)
      })
    } else if (changedField === content.Distance) {
      const distanceParam = urlParams.get('distance');
      if (distanceParam) {
        this.updateUrlParam('distance', e);
      }
      this.setState({ pageNumber: 1, distance: e }, () => { //selectedCompany: 'Show all'
        // this.formRef.current.setFieldsValue({
        //   company: 'Show all'
        // });
        values.distance = e;
        this.submitFilter(values);
      })
    } else {
      const distanceParam = urlParams.get('occupationIds');
      if (distanceParam) {
        const formattedOccupationIds = Array.isArray(e) ? e.join(',') : e;
        this.updateUrlParam('occupationIds', formattedOccupationIds);
      }
      this.setState({ selectedOccupations: e, pageNumber: 1 }, () => {
        // selectedCompany: 'Show all'
        // this.formRef.current.setFieldsValue({
        //   company: 'Show all'
        // });
        this.submitFilter(values)
      })
    }
  }

  submitFilter = async (values) => {
    this.setState({ loader: true })
    let url = 'search/occupation?apiKey=' + apiKey + '&pagination=' + this.state.pageNumber + '&pageSize=' + this.state.pageSize + '&resultsSortOrder=BestMatch&cpa=true&cpac=true&cpc=true&countryCode=GB';
    //CHECK IF COOKIES EXIST
    if (getCookie(cookie_list.LATITUDE) && getCookie(cookie_list.LONGITUDE)) {
      url += '&longitude=' + getCookie(cookie_list.LONGITUDE)
      url += '&latitude=' + getCookie(cookie_list.LATITUDE)
    }
    if (!this.state.isCompanySearch && getCookie(cookie_list.LOCATION) && getCookie(cookie_list.LOCATION) === values.location) {
      await this.fetchOccupationCount()
    } else if (values.location && values.location !== '') {

      // eslint-disable-next-line
      this.state.locationList.map(async obj => {
        let loc = obj.City + (obj.City && obj.County ? ', ' : '') + obj.County
        if (loc === values.location) {
          url += '&longitude=' + obj.Longitude
          url += '&latitude=' + obj.Latitude
          await this.setState({ location: values.location, latitude: obj.Latitude, longitude: obj.Longitude }, () => this.fetchOccupationCount())
          if (!this.state.isCompanySearch) {
            if (getCookie(cookie_list.TOKEN)) {
              console.log("LATITUDE", getCookie(cookie_list.LATITUDE), obj.Latitude)
              let formdata = JSON.stringify({
                MaxDistanceKm: 0,
                LocationName: values.location,
                Country: obj.Country,
                Longitude: obj.Longitude || getCookie(cookie_list.LONGITUDE),
                Latitude: obj.Latitude || getCookie(cookie_list.LATITUDE),
                LocationType: obj.Type,
                CreatedOn: moment().format('YYYY-MM-DD'),
                ApiKey: apiKey
              });
              await apiCall(`users/location?apiKey=${apiKey}`, 'POST', formdata, false, true).then(() => {
                this.saveLocationCookies(values.location, obj.Latitude, obj.Longitude, obj.Type, obj.Country)
              })
            } else {
              this.saveLocationCookies(values.location, obj.Latitude, obj.Longitude, obj.Type, obj.Country)
            }
          }
        }
      })
    } else {
      await this.fetchOccupationCount()
    }
    if (!this.state.isCompanySearch) {
      //SAVE OCCUPATIONS IN API/COOKIE 
      await SearchJobDataHandling.searchData(this.state.selectedOccupations);
    }
    // eslint-disable-next-line
    this.state.selectedOccupations.map((obj) => {
      url += "&occupationIds=" + obj
    })
    if (values.distance) {
      url += '&distance=' + values.distance
      //create cookie only if the co. search is false, as client dont want to save these searches on co.
      if (!this.state.isCompanySearch) { createCookie(cookie_list.DISTANCE, values.distance, 4320, '/') }
    }

    if (this.state.selectedCompany !== 'Show all') {
      // this.state.companyList.map((obj,index) => {
      //   console.log('obj',obj)
      //   if(index>0){
      //     url += "&companyNames=" + obj.value
      //   }
      // })
      url += "&companyNames=" + this.state.selectedCompany
    }
    console.log("url", url)
    this.fetchJobList(url)
  }
  saveLocationCookies = (location, latitude, longitude, locationType, country) => {
    createCookie(cookie_list.LOCATION, location, 4320, '/')
    createCookie(cookie_list.LONGITUDE, longitude, 4320, '/')
    createCookie(cookie_list.LATITUDE, latitude, 4320, '/')
    createCookie(cookie_list.LOCATIONTYPE, locationType || '', 4320, '/')
    createCookie(cookie_list.COUNTRY, country || '', 4320, '/')
  }
  fetchJobList = async (url) => {
    this.setState({ listLoader: true })
    this.setState({ apicallText: url })
    await apiCall(url, 'GET').then((res) => {
      if (res?.status === 200 && res.data) {
        this.setState({ totalJobs: res.data.Total });
        this.setState({ jobList: res.data.Results }, () => {
          let isJobPresentInList = this.state.jobId ? res.data.Results?.filter(item => item.Id.toString() === this.state.jobId) : null
          if (isJobPresentInList && isJobPresentInList.length > 0) {
            let id = 'id' + this.state.jobId.toString()
            document.getElementById(id).scrollIntoView();
          }
        })
      }
      // else {
      //   //notificationPop(notificationContent.FAILED, notificationContent.SOMETHING_WRONG)
      // }
      this.setState({ listLoader: false })
      this.setState({ loader: false })

    }).catch(() => {
      this.setState({ listLoader: false })
      this.setState({ loader: false })
    })
  }

  fetchSimilarJobs = async () => {
    this.setState({ listLoader: true })
    await apiCall('personalisedjobs/similar?apiKey=' + apiKey + '&jobId=' + this.state.jobId + '&source=' + os, 'GET', '', false, true).then((res) => {
      if (res?.status === 200) {
        this.setState({ jobList: res?.data, listLoader: false })
      } else {
        this.setState({ listLoader: false })
        //notificationPop(notificationContent.FAILED, notificationContent.SOMETHING_WRONG)
      }
      this.setState({ loader: false })
    }).catch(() => {
      this.setState({ loader: false, listLoader: false })
    })
  }

  SetSelectedJobs = (e) => {
    var jobs = this.state.selectedJobs
    if (e.target.checked) {
      jobs.push(e.target.value)
    }
    else {
      for (var i = 0; i < jobs.length; i++) {
        if (jobs[i] === e.target.value) {
          jobs.splice(i, 1)
        }
      }
    }
    this.setState({ selectedJobs: jobs })
  }

  submitApply = (value) => {
    this.setState({ loader: true })
    let applyJobs = []
    if (value) {
      this.state.jobList.map((item) => {
        applyJobs.push(item.Id)
      })
    }
    else {
      if (this.state.selectedJobs.length > 0) {
        applyJobs = this.state.selectedJobs
      }
      else {
        notificationPop('warning', notificationContent.FAILED, 'Select at least on job')
        this.setState({ loader: false })
      }
    }
    applyJobs.map((obj) => {
      let form_data = {
        JobId: obj,
        Referrer: "applygateway",
        Source: os,
        AgreeBuyerEmailAlerts: true,
        AgreeBuyerCvSearch: true,
        Question1Response: true,
        Question2Response: true,
        Question3Response: true,
        Multi: true,
        ApiKey: apiKey
      }
      apiCall('jobshortlists/apply', 'POST', form_data, false, true).then((res) => {
        if (res?.status === 200) {
          this.setState({ loader: false })
          googleAnalyticsApplyOnSite()
          notificationPop('success', notificationContent.SUCCESS, 'Applied successfully')
        } else {
          notificationPop('error', notificationContent.FAILED, res?.data?.message)
          this.setState({ loader: false })
        }

      })
    })

  }

  checkForLocation = (e) => {
    let result = true
    if (!this.state.locationList.length > 0) {
      this.formRef.current.setFieldsValue({
        location: ''
      });
    }
    for (const item of this.state.locationList) {
      let val = item.City + (item.County && item.City ? ', ' : '') + item.County;
      if (e.target.value === val) {
        this.formRef.current.setFieldsValue({
          location: e.target.value
        });
        break
      }
      else {
        this.formRef.current.setFieldsValue({
          location: ''
        });
      }
    }
    return result
  }

  setPageNumber = (page) => {
    this.setState({ pageNumber: page }, (() => {
      this.onLoadFunction();
    }))
  }

  redirectToDetails = (Id) => {
    this.props.history.push(
      `/job-detail?id=${Id}`,
      { pageNumber: this.state.pageNumber }
    )
  }
  EmptyListMessage = () => {
    let message;
    if (this.state.selectedCompany && this.state.selectedCompany !== 'Show all') {
      message = `Sorry there are no jobs at ${this.state.selectedCompany} with your occupation/location selection. Please register and we will alert you when there are new ones posted`;
    } else {
      message = "Sorry there are currently no jobs with your occupation/location selection. Please register and we will alert you when there are new ones posted.";
    }
    return message;
  }
  render() {
    document.title = 'Job Search - ' + WEBSITE_NAME
    return (
      <Spin spinning={this.state.loader}>
        <div className='titlemargin bg-position' style={{ backgroundImage: `url(${require('../../assets/' + FOLDER_NAME + '/search-bg.jpeg')})` }}>
          <div className='vc_column-inner container'>
            <Title className='mb-0'><span className='accent'>{content.JobSearch}</span></Title>
            <Title level={3} className='text-white my-0'>{content.JobSubtitle}</Title>
          </div>
        </div>
        <div className='sm-margins container'>
          {this.state.jobId && this.state.similarJobs ? (<div>
            <Title level={3}><span className='accent'>{content.ApplyAll}</span></Title>
            <Title level={3}><span className='accent'>{content.Similar}</span></Title>
            <div className='mb-4'>
              <Space>
                <Button onClick={() => this.submitApply(false)} type="primary" size="large">Apply</Button>
                <Button onClick={() => this.submitApply(true)} type="primary" size="large">Apply to all!</Button>
                <Button onClick={() => {
                  this.setState({ jobId: null, similarJobs: false }, () => {
                    this.onLoadFunction()
                  })
                }} type="primary" size="large">Back to search results</Button>
              </Space>
            </div>
          </div>
          ) : (null)}
          {/* Comment the below line when publishing on live */}
          {/* {this.state.apicallText!==''?<Text>{this.state.apicallText}</Text>:null} */}
          <Row gutter={[{ xs: 0, sm: 32 }, { xs: 8, sm: 16 }]}>
            {this.state.jobId && this.state.similarJobs ? (null) : (
              <Col xs={24} lg={6} xl={6} xxl={6}>
                <Form onFinish={(values) => {
                  this.setState({ pageNumber: 1 }, () => {
                    // this.formRef.current.setFieldsValue({
                    //   company: 'Show all'
                    // });
                    this.submitFilter(values, false)
                  })
                }} ref={this.formRef} layout="vertical">
                  <Collapse defaultActiveKey={['1']} ghost className='filter-panel' expandIcon={() => <FilterOutlined />}>
                    <Panel header={<Title level={5}>Filters</Title>} key="1">
                      <Form.Item
                        label={content.locationSearchLabel}
                        name="location"
                        rules={[
                          {
                            required: true,
                            message: 'Enter your location'
                          }
                        ]}
                      >
                        <AutoComplete
                          options={this.state.options}
                          // placeholder="eg. London"
                          onBlur={this.checkForLocation}
                          onSearch={this.fetchLocationList} />
                      </Form.Item>
                      <Form.Item>
                        <Button htmlType="submit" type="primary" size="large">{content.Update}</Button>
                      </Form.Item>
                      <Form.Item
                        label="Distance"
                        name="distance"
                        className='search-form'
                      >
                        <Select defaultValue="5" onChange={(e) => this.setSelectedOccupations(e, content.Distance)}>
                          <Option key='1' value='5'>Within 5 miles</Option>
                          <Option key='2' value='10'>Within 10 miles</Option>
                          <Option key='3' value='15'>Within 15 miles</Option>
                          <Option key='4' value='20'>Within 20 miles</Option>
                          <Option key='5' value='25'>Within 25 miles</Option>
                          <Option key='6' value='50'>Within 50 miles</Option>
                          <Option key='7' value='100'>Within 100 miles</Option>
                          <Option key='8' value='250'>Within 250 miles</Option>
                          <Option key='9' value='500'>Within 500 miles</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item label={<Space>{content.Occupations}<Button type="link" className='qcont' onClick={() => this.setSelectedOccupations([])} >clear all</Button></Space>}>
                        <Checkbox.Group value={this.state.selectedOccupations} options={this.state.occupationList} onChange={this.setSelectedOccupations} className="list-checkbox" />
                      </Form.Item>
                      <Form.Item label={<Space>{content.Company}<span className='text-transform-none'>(Jobs in Total)</span></Space>} className='search-form' name="company">
                        <Select
                          defaultValue="Show all"
                          showSearch
                          allowClear
                          options={this.state.companyList}
                          onChange={(e) => this.setSelectedOccupations(e, content.Company)}
                          filterOption={(input, option) => option.value.toLowerCase().includes(input.toLowerCase())}
                          filterSort={(optionA, optionB) =>
                            optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Panel>
                  </Collapse>
                </Form>
              </Col>)}
            <Col xs={24} lg={18} xl={18} xxl={18}>
              <List
                locale={{ emptyText: this.EmptyListMessage() }}
                loading={this.state.listLoader}
                dataSource={this.state.jobList}
                pagination={{
                  onChange: page => {
                    this.setPageNumber(page);
                  },
                  pageSize: this.state.pageSize,
                  showSizeChanger: false,
                  current: this.state.pageNumber,
                  total: this.state.totalJobs,
                  disabled: this.state.similarJobs
                }}
                renderItem={(item, index) => (<Row gutter={[8, 0]}>
                  {this.state.jobId && this.state.similarJobs ? (
                    <Col span={1} className='place-center'><Checkbox className="theme-check" onChange={(e) => this.SetSelectedJobs(e)} value={item.Id}></Checkbox></Col>
                  ) : (null)}
                  <Col span={23}>
                    <Card id={'id' + item.Id} className="mb-4 xs-logo-placement">
                      <Row gutter={[24, 24]}>
                        <Col xs={{ span: 24, order: 2 }} lg={16} xl={{ span: 16, order: 1 }} xxl={16}>
                          <Title key={item.Id + index} level={3}><span className='accent'>{item.Title}</span></Title>
                          {/* Location */}
                          <p className='mb-0' key={index + item.City}>
                            <Text type="secondary">
                              <span>{item.City || ''}{item.City && item.State ? ", " : ''}</span>
                              <span>{item.State || ''}{(item.City || item.State) && item.Postcode ? ", " : ''}</span>
                              <span>{CommonFunctionalities.PostCodeText(item.Postcode) || ''}</span>
                            </Text>
                          </p>

                          <p className='mb-0' key={index + item.CompanyName}>
                            <Text strong>{item.CompanyName}</Text>
                          </p>
                          <p className='mb-0' key={index + item.Salary}>
                            <Text strong>{item?.Salary?.replace(/&pound;/g, '£')}</Text>
                          </p>
                        </Col>
                        <Col xs={{ span: 24, order: 1 }} lg={8} xl={{ span: 8, order: 2 }} xxl={8} className='job-logo-placement'>
                          <div className="bg-white border-solid company-logo">
                            <Image
                              width={200}
                              src={item.Logo}
                              preview={false}
                              className="p-2"
                            />
                          </div>
                        </Col>
                        <Col xs={{ span: 24, order: 3 }} lg={24} xl={{ span: 24, order: 3 }} xxl={24}>
                          {/* <div className='text-ellipsis' dangerouslySetInnerHTML={{ __html: item.Description }} /> */}
                          <p className='text-ellipsis'>{item.Description.replace(regex, ' ').replace(/&nbsp;/g, ' ').replace(/&rsquo;/g, "'").replace(/&#39;/g, "'").replace(/&pound;/g, "£")}</p>
                        </Col>
                        {/* TODO: pass job id */}
                        <Col xs={{ span: 24, order: 4 }} lg={24} xl={{ span: 24, order: 4 }} xxl={24}>
                          <div className='text-right'>
                            <Button onClick={() => this.redirectToDetails(item.Id)} type="primary" size="large" className='hover-outline'>View Details</Button>
                          </div>
                        </Col>
                      </Row>
                    </Card></Col></Row>
                )}
              />
            </Col>
          </Row>
        </div>
      </Spin>
    )
  }
}
export default JobSearch
