import React, { Component } from 'react'
import { Modal, Form, Input, Button, Spin } from 'antd'
import { apiCall } from '../utils/http'
import { content } from '../constants/content'

class OTPModal extends Component {
  constructor(props) {
    super(props)
    this.state = { email: props.email, loadResendEmail:false, ResendEmailText:content.ResendEmail }
  }

  //GET USER TOKEN
  fetchUserToken = async () => {
    this.setState({ loadResendEmail: true })
    // eslint-disable-next-line no-undef
    let apiKey = process.env.REACT_APP_API_KEY

    let form_data = {
      Email: this.state.email,
      ApiKey: apiKey
    }

    await apiCall('users/otp/request', 'POST', form_data).then((res) => {
      //DO NOTHING
     if(res?.data?.Success){ 
      this.setState({ResendEmailText: content.sentEmail})
    } else {
      this.setState({ResendEmailText: res?.data?.message || content.errorMessage})
    }
    }).catch(() => {
      //DO NOTHING
      this.setState({ResendEmailText: content.errorMessage})
    })
    
    setTimeout(() => {
      this.setState({ResendEmailText: content.ResendEmail})
     }, 3000);

    this.setState({ loadResendEmail: false })
  }

  validateOTP = async (values) => {
    // eslint-disable-next-line no-undef
    let apiKey = process.env.REACT_APP_API_KEY
    let form_data = {
      Email: this.state.email,
      Code: values?.otp || '',
      ApiKey: apiKey
    }

    await apiCall('users/otp/confirm', 'POST', form_data).then((res) => {
      this.setState({ loader: false })
      return this.props.response(res)
    }).catch((err) => {
      this.setState({ loader: false })
      return this.props.response(err?.response)
    })
  }

  render() {
    return (
      <Spin spinning={this.state.loader}>
        <Modal 
          visible={true}
          closable={false}
          footer={null}
          title={content.ConfirmYou}
        >
          <p>{content.EmailedOTP}</p>
          <Form onFinish={(values) => this.setState({ loader: true }, () => this.validateOTP(values))} layout="vertical">
            <Form.Item 
              label='Enter OTP' 
              name='otp'
              rules={[{ 
                required: true, 
                message: 'Enter OTP Key' 
              }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button htmlType='submit' type="primary" size="large">
                Confirm OTP
              </Button>
            </Form.Item>
          </Form>
          <p>Still no code? 
            <Button style={{color:this.state.ResendEmailText===content.sentEmail?'green':'#1890ff'}} disabled={this.state.loadResendEmail} type='link' loading={this.state.loadResendEmail} onClick={() => this.fetchUserToken()} className="qcont">
             {this.state.ResendEmailText}
            </Button>
          </p>
        </Modal>
      </Spin>
    )
  }
}

export default OTPModal