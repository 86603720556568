import React, { Component } from 'react';
import { Spin, Typography, Col, Row, Card } from 'antd'
import { FOLDER_NAME, WEBSITE_NAME } from "../../utils/process";
import { content } from '../../constants/content';

const { Title } = Typography
const rawHtml = `<div id='crmWebToEntityForm' class='zcwf_lblLeft crmWebToEntityForm'>
<meta name='viewport' content='width=device-width, initial-scale=1.0'>
 <META HTTP-EQUIV ='content-type' CONTENT='text/html;charset=UTF-8'>
 <form action='https://crm.zoho.eu/crm/WebToLeadForm' name=WebToLeads410265000001202002 method='POST' onSubmit='javascript:document.charset="UTF-8"; return checkMandatory410265000001202002()' accept-charset='UTF-8'>
<input type='text' style='display:none;' name='xnQsjsdp' value='3c84483c277d4654a1b4c396d146b2772d4ea572e85f695b138c671bb7ad8949'></input> 

<input type='hidden' name='zc_gad' id='zc_gad' value=''></input>
<input type='text' style='display:none;' name='xmIwtLD' value='037371796916d85390ee5b5ab55ac88839c7ce955c6e2361db42fa0b3f42401f'></input> 

<input type='text'  style='display:none;' name='actionType' value='TGVhZHM='></input>
<input type='text' style='display:none;' name='returnURL' value='https&#x3a;&#x2f;&#x2f;carejobs.io&#x2f;employer-contact-thanks' > </input>
   <!-- Do not remove this code. -->
  <div class='ant-form-item'>
    <div class='zcwf_col_lab'><label for='Company'>Company name<span style='color:red;'>*</span></label></div>
    <div class='zcwf_col_fld'>
        <input type='text' id='Company' name='Company' maxlength='200' class="ant-input"></input>
        <div class='zcwf_col_help'></div>
    </div>
  </div>
  <div class="ant-row" style="margin-left: -8px; margin-right: -8px; row-gap: 0px;">
    <div class='ant-col ant-col-xs-24 ant-col-sm-24 ant-col-lg-12' style="padding-left: 8px; padding-right: 8px;">
      <div class='zcwf_col_lab'><label for='First_Name'>Name<span style='color:red;'>*</span></label></div>
      <div class='zcwf_col_fld'>
          <input type='text' id='First_Name' name='First Name' maxlength='40' class="ant-input"></input>
          <div class='zcwf_col_help'></div>
      </div>
      <div class="mb-2"><span class="ant-typography ant-typography-secondary">First name</span></div>
    </div>

    <div class='ant-col ant-col-xs-24 ant-col-sm-24 ant-col-lg-12' style="padding-left: 8px; padding-right: 8px;">
      <div class='zcwf_col_lab'><label for='Last_Name' style="visibility: hidden;">Last Name<span style='color:red;'>*</span></label></div>
      <div class='zcwf_col_fld'>
          <input type='text' id='Last_Name' name='Last Name' maxlength='80' class="ant-input"></input>
          <div class='zcwf_col_help'></div>
      </div>
      <div class="mb-2"><span class="ant-typography ant-typography-secondary">Last name</span></div>
    </div>
  </div>

  <div class="ant-row" style="margin-left: -8px; margin-right: -8px; row-gap: 0px;">
    <div class='ant-col ant-col-xs-24 ant-col-sm-24 ant-col-lg-12' style="padding-left: 8px; padding-right: 8px;">
      <div class='ant-form-item'>
        <div class='zcwf_col_lab'><label for='Email'>Email Address<span style='color:red;'>*</span></label></div>
        <div class='zcwf_col_fld'>
            <input type='text' ftype='email' id='Email' name='Email' maxlength='100' class="ant-input"></input>
            <div class='zcwf_col_help'></div>
        </div>
      </div>
    </div>
    <div class='ant-col ant-col-xs-24 ant-col-sm-24 ant-col-lg-12' style="padding-left: 8px; padding-right: 8px;">
      <div class='ant-form-item'>
        <div class='zcwf_col_lab'><label for='Phone'>Phone Number<span style='color:red;'>*</span></label></div>
        <div class='zcwf_col_fld'>
            <input type='text' id='Phone' name='Phone' maxlength='30' class="ant-input"></input>
            <div class='zcwf_col_help'></div>
        </div>
      </div>
    </div>
  </div>
  <div class='ant-form-item'>
    <div class='zcwf_col_lab'><label for='Description'>Message</label></div>
    <div class='zcwf_col_fld'>
        <textarea rows="4" id='Description' name='Description' class="ant-input"></textarea>
        <div class='zcwf_col_help'></div>
    </div>
  </div>
  <div style="display: flex;flex-wrap: wrap;gap: 10px;">
    <input type='reset' style="display: block; width: auto; visibility: visible;" class='ant-btn ant-btn-primary ant-btn-lg' name='reset' value='Reset' title='Reset'>
    <input type='submit' id='formsubmit' style="display: block; width: auto; visibility: visible;" class='formsubmit ant-btn ant-btn-primary ant-btn-lg' value='Contact Us' title='Contact Us'>
  </div>
  <script id="contactScript">
  function validateEmail410265000001202002()
  {
      var form = document.forms['WebToLeads410265000001202002'];
      var emailFld = form.querySelectorAll('[ftype=email]');
      var i;
      for (i = 0; i < emailFld.length; i++)
      {
          var emailVal = emailFld[i].value;
          if((emailVal.replace(/^\s+|\s+$/g, '')).length!=0 )
          {
              var atpos=emailVal.indexOf('@');
              var dotpos=emailVal.lastIndexOf('.');
              if (atpos<1 || dotpos<atpos+2 || dotpos+2>=emailVal.length)
              {
                  alert('Please enter a valid email address. ');
                  emailFld[i].focus();
                  return false;
              }
          }
      }
      return true;
  }

     function checkMandatory410265000001202002() {
      var mndFileds = new Array('Company','First Name','Last Name','Email','Phone');
      var fldLangVal = new Array('Company','First\x20Name','Last\x20Name','Email','Phone');
      for(i=0;i<mndFileds.length;i++) {
        var fieldObj=document.forms['WebToLeads410265000001202002'][mndFileds[i]];
        if(fieldObj) {
          if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length==0) {
           if(fieldObj.type =='file')
              {
               alert('Please select a file to upload.');
               fieldObj.focus();
               return false;
              }
          alert(fldLangVal[i] +' cannot be empty');
                    fieldObj.focus();
                    return false;
          }  else if(fieldObj.nodeName=='SELECT') {
if(fieldObj.options[fieldObj.selectedIndex].value=='-None-') {
              alert(fldLangVal[i] +' cannot be none');
              fieldObj.focus();
              return false;
             }
          } else if(fieldObj.type =='checkbox'){
              if(fieldObj.checked == false){
              alert('Please accept  '+fldLangVal[i]);
              fieldObj.focus();
              return false;
             }
           }
           try {
               if(fieldObj.name == 'Last Name') {
              name = fieldObj.value;
                 }
          } catch (e) {}
          }
      }
      if(!validateEmail410265000001202002()){return false;}
      document.querySelector('.crmWebToEntityForm .formsubmit').setAttribute('disabled', true);
  }

function tooltipShow410265000001202002(el){
  var tooltip = el.nextElementSibling;
  var tooltipDisplay = tooltip.style.display;
  if(tooltipDisplay == 'none'){
      var allTooltip = document.getElementsByClassName('zcwf_tooltip_over');
      for(i=0; i<allTooltip.length; i++){
          allTooltip[i].style.display='none';
      }
      tooltip.style.display = 'block';
  }else{
      tooltip.style.display='none';
  }
}
</script>
  </form>
</div>`;

class Employer extends Component {
  constructor() {
    super()
    this.state = {
      loader: false,
      isModalVisible: false
    }
  }
  componentDidMount(){
    const script = document.getElementById('contactScript').innerHTML;
    window.eval(script);
  }
  render() {
    document.title = 'Employer - ' + WEBSITE_NAME
    return (
      <Spin spinning={this.state.loader}>
        <div className='titlemargin bg-position' style={{ backgroundImage: `url(${require('../../assets/' + FOLDER_NAME + '/employer-bg.jpg')})` }}>
          <div className='vc_column-inner container'>
            <Title className='mb-0'><span className='accent'>{content.PostJobs}</span></Title>
            <Title level={3} className='text-white my-0'>{content.ContactSubtitle}</Title>
          </div>
        </div>
        <div className='sm-margins container'>
          <Row>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={18}>
              {/* Contact us FORM */}
              <Card
                className='crd-header-style'
                bordered={false}
                title={content.ContactUs}
              >
                <div dangerouslySetInnerHTML={{ __html: rawHtml }}></div>
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    )
  }
}
export default Employer