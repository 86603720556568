import React from 'react'
import { Route } from 'react-router-dom'
import 'antd/dist/antd.min.css'
import './App.css'
/**
 *
 * @param {Object} Route parameters
 * @description Route parameters must contain layout, component and other props. Layout has to be the master oage.
 */
function routeHandler({ layout, component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => React.createElement(
        layout,
        {
          ...props,
          ...rest,
        },
        React.createElement(component, {
          ...props,
          ...rest,
        }),
      )}
    />
  )
}

export default routeHandler
