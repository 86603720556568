import React, { Component } from 'react'
import { Image } from 'antd'
import imageLogo from '../../assets/carejobs/logo.png';
import { FOLDER_NAME, CONTACT_US_LINK } from "../../utils/process";
class Logo extends Component {
    constructor() {
        super()
    }
    render() {
        return (
            <div className='place-center' style={{height:'100vh'}}>
                <Image width={500} src={require('../../assets/' + FOLDER_NAME + '/logo.png')} preview={false} alt='applygateway' />
            </div>
        )
    }
}
export default Logo;