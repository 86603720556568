import React, { Component } from 'react'
import { Card, Col, Row, Image, Typography, Avatar, Space } from 'antd'
import moment from 'moment';
import "../article/index.css"
import { CheckCircleOutlined, } from "@ant-design/icons"
const { Paragraph, Text } = Typography;
const { Meta } = Card;

class ArticleCard extends Component {
    constructor(){
        super()
        this.state={
            loading: false,
            data:null,
        }
    }
    componentDidMount () {
        this.setState({data:this.props.article})
    }
    render(){
        return(
            // <Card>
                <Row gutter={[8, 0]}>
                    <Col xs={24} lg={5}>
                        <Image
                            className='article-image'
                            alt='Article image' 
                            src={this.state.data?.BuyerLogo}
                            preview={false}
                        />
                    </Col>
                    <Col xs={24} lg={19} className="article-content">
                                <Typography.Title
                                    level={4}
                                >
                                    {this.state.data?.BlogTitle}
                                </Typography.Title>
                                <Paragraph
                                    className='article-text'
                                    type='secondary'
                                    ellipsis={{
                                    rows:2,
                                    expandable: false,
                                    }}
                                    
                                > {this.state.data?.BlogText}
                                </Paragraph>
                        <div className='writer-details'>
                        <div className='writer-content px-3'>
                            {/* Writer's details */}
                            <div>
                                <Card
                                    bordered={false}
                                    style={{
                                    width: 300,
                                    }}
                                >
                                    <Meta
                                    avatar={<Avatar src={this.state.data?.BuyerLogo} />}
                                    title={this.state.data?.BuyerName}
                                    description={<Space><CheckCircleOutlined className='verified-icon' /><i>Verified Writer</i></Space>}
                                    />
                                </Card>
                            </div>
                            <div >
                                <Text className='article-text' type="secondary">{moment(this.state.data?.BlogDate).format('DD MMM')}</Text>
                            </div>
                        </div> 
                        </div>

                    </Col>
                </Row>
            // </Card>
        )
    }
}
export default ArticleCard;