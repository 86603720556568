import axios from 'axios'
import { cookie_list, getCookie } from "../utils/cookie";

// eslint-disable-next-line no-undef
const URL = process.env.REACT_APP_API_URL
const SEARCH_URL = process.env.REACT_APP_API_SEARCH_URL

async function apiCall (url, method, body = '', search = false, token = false) {
  if(search) {
    url = SEARCH_URL + url
  } else {
    url = URL + url
  }
  let headers = { 'Content-Type': 'application/json' }
  if(token){
    headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getCookie(cookie_list.TOKEN) }
  }

  if(method === 'GET') {
    return await axios.get(
      url, 
      { headers }
    ).then((res) => { return res })
    .catch((err) => { return err?.response })
  } else if (method === 'POST') {
    return await axios.post(
      url, 
      body,
      { headers }
    ).then((res) => { return res })
    .catch((err) => { return err?.response })
  } else if (method ==='DELETE'){
    return await axios.delete(
      url,
      { headers }
    ).then((res)=>{ return res })
    .catch((err)=>{ return err?.response })
  } else {
    return await axios.put(
      url, 
      body,
      { headers }
    ).then((res) => { return res })
    .catch((err) => { return err?.response })
  }
}

export { apiCall }