import moment from "moment"

const cookie_list = {
  TOKEN: 'token',
  FIRSTNAME: 'firstName',
  EMAIL: 'email',
  EXPIRY: 'expiry',
  OCCUPATIONS: 'occupations',
  LOCATION: 'location',
  DISTANCE: 'distance',
  LONGITUDE: 'longitude',
  LATITUDE: 'latitude',
  LOCATIONTYPE: 'locationType',
  COUNTRY: 'country',
  CV:'cv',
  POSTCODE:'postCode',
  UTMCAMPAIGN:'utm_campaign',
  IPADDRESS:'ip_address',
}
// CREATE COOKIE
function createCookie(name, value, hours, path) {
  let cookie = `${name}=${value};`
  let expires = ''
  if (hours) {
    const date = new Date()
    date.setTime(date.getTime() + hours * 60 * 60 * 1000)
    expires = `; expires=${date.toGMTString()}`
  }
  cookie += `${expires};`
  if (path) cookie += `path=${path};`
  if (typeof window !== 'undefined') {
    document.cookie = cookie
  }
}
// GET COOKIE
function getCookie(name) {
  const cookie_name = `${name}=`
  let cookie_split
  if (typeof window !== 'undefined') {
    cookie_split = document.cookie.split(';')
    for (let i = 0; i < cookie_split.length; i++) {
      let c = cookie_split[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(cookie_name) === 0) return c.substring(cookie_name.length, c.length)
    }
  }
  return null
}
// DELETE COOKIE
function deleteCookie(name) {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`
}
//LOGOUT
function logout() {
  deleteCookie(cookie_list.EMAIL)
  deleteCookie(cookie_list.FIRSTNAME)
  deleteCookie(cookie_list.TOKEN)
  deleteCookie(cookie_list.EXPIRY)
  deleteCookie(cookie_list.OCCUPATIONS)
  deleteCookie(cookie_list.LOCATION)
  deleteCookie(cookie_list.DISTANCE)
  deleteCookie(cookie_list.LONGITUDE)
  deleteCookie(cookie_list.LATITUDE)
  deleteCookie(cookie_list.COUNTRY)
  deleteCookie(cookie_list.LOCATIONTYPE)
  deleteCookie(cookie_list.CV)
  deleteCookie(cookie_list.POSTCODE)
  deleteCookie(cookie_list.UTMCAMPAIGN)
  deleteCookie(cookie_list.IPADDRESS)
  window.location.href = '/login'
}

function refreshCookies() {
  createCookie(cookie_list.TOKEN, getCookie(cookie_list.TOKEN) || '', 4320, '/')
  createCookie(cookie_list.EMAIL, getCookie(cookie_list.EMAIL) || '', 4320, '/')
  createCookie(cookie_list.FIRSTNAME, getCookie(cookie_list.FIRSTNAME) || '', 4320, '/')
  createCookie(cookie_list.EXPIRY, moment().add(180, 'days').format('YYYY-MM-DD HH:mm:ss'), 4320, '/')
  createCookie(cookie_list.OCCUPATIONS, getCookie(cookie_list.OCCUPATIONS) || [], 4320, '/')
  createCookie(cookie_list.LOCATION, getCookie(cookie_list.LOCATION) || '', 4320, '/')
  createCookie(cookie_list.DISTANCE, getCookie(cookie_list.DISTANCE) || '10', 4320, '/')
  createCookie(cookie_list.LONGITUDE, getCookie(cookie_list.LONGITUDE) || '', 4320, '/')
  createCookie(cookie_list.LATITUDE, getCookie(cookie_list.LATITUDE) || '', 4320, '/')
  createCookie(cookie_list.LOCATIONTYPE, getCookie(cookie_list.LOCATIONTYPE) || '', 4320, '/')
  createCookie(cookie_list.COUNTRY, getCookie(cookie_list.COUNTRY) || '', 4320, '/')
  createCookie(cookie_list.CV, getCookie(cookie_list.CV) || '', 4320, '/')
  createCookie(cookie_list.POSTCODE, getCookie(cookie_list.POSTCODE) || '', 4320, '/')
  createCookie(cookie_list.UTMCAMPAIGN, getCookie(cookie_list.UTMCAMPAIGN) || '', 4320, '/')
  createCookie(cookie_list.IPADDRESS, getCookie(cookie_list.IPADDRESS) || '', 4320, '/')
  return true
}

export { cookie_list, createCookie, deleteCookie, getCookie, logout, refreshCookies }
