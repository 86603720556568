import React, { Component } from 'react'
import { Layout, Row, Col, Menu, Typography, Button, Image, Drawer, Space, Card, Form, Input } from "antd"
import { FOLDER_NAME, CONTACT_US_LINK } from "../utils/process";
import { content } from '../constants/content';
import { MenuOutlined, CloseOutlined } from "@ant-design/icons"
import { cookie_list, getCookie } from '../utils/cookie';
const { Footer } = Layout
const { Title, Text } = Typography
class EmployerLayoutFooter extends Component {
  showMobileMenu = () => {
    this.setState({ mobileMenu: true })
  }
  closeMobileMenu = () => {
    this.setState({ mobileMenu: false })
  }
  validateFields = (value) => {
    this.props.history.push(
      `/login`,
      { email: value.email }
    )
  }
  constructor(props) {
    super(props)
    this.state = {
      mobileMenu: false
    }
    this.formRef = React.createRef()
  }
  render() {
    return (
      <div>
        <Footer className='app-footer relative' style={{ marginTop: "100px" }} >
          {/*  footer start */}
          <div className='curved-footer'></div>
          <div className='blog-footer-min-height'>
            <Row gutter={[24, 24]}>
              <Col xs={24} lg={11}>
                <div className="place-center h-full">
                  <Title level={3} className="text-white">Get our blog stories delivered to your inbox weekly.</Title>
                  { getCookie(cookie_list.TOKEN)?null:(
                    <Form
                      layout="horizontal"
                      requiredMark={false}
                      ref={this.formRef}
                      onFinish={this.validateFields}
                      className="search-form text-left"
                    >
                      <Row gutter={[16, 0]}>
                        <Col xs={24} sm={24} lg={16}>
                          <Form.Item
                            name={'email'}
                            rules={[
                              {
                                type: 'email',
                                message:'Enter a valid email'
                              },
                              {required: true, message:'Email is required'}
                            ]}
                          >
                            <Input placeholder="Your Email" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={8}>
                          <Form.Item name="submit">
                            <Button htmlType='submit' type="primary" size="large">
                              {content.Getstarted}
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  )}
                  <p className="text-base text-white">Submissions reveived after 9pm will get a response the following day.</p>
                </div>
              </Col>
              <Col xs={24} md={24} lg={13}>
                <div className="rounded-2xl-card article-snippet place-center">
                  <Card cover={<img alt="Carejobs testimonials" src={require('../assets/' + FOLDER_NAME + '/testimonial-1.png')} />}>
                    <div>
                      <Text strong className="text-base">The best articles every week</Text>
                    </div>
                    <Text className="text-base">Make sure you stay in the loop on all the latest trends & insights</Text>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
          {/* footer end */}
        </Footer>
      </div>
    )
  }
}

export default EmployerLayoutFooter