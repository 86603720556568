import React, { Component } from 'react'
import { Spin, Typography, Image, Row, Col, Avatar, Card, Space, notification } from 'antd'
import { apiCall } from '../../utils/http';
import moment from 'moment';
import JobSearchBox from '../jobsearchbox';
import '../article/index.css'
import { CheckCircleOutlined, } from "@ant-design/icons"
import { notificationContent } from '../../constants/notifications';
import { content } from '../../constants/content';
let apiKey = process.env.REACT_APP_API_KEY
const {Paragraph, Text}=Typography;
const { Meta } = Card;
function notificationPop(type, header, message) {
    notification.destroy()
    notification[type]({
      message: header,
      description: message
    })
  }
class ArticleDetail extends Component {
    constructor(){
        super()
        this.state={
            loading: false,
            data:null,
            pageNumber:null,
            articleId:null,
        }
    }
    componentDidMount () {
        this.setState({ pageNumber: this.props?.location?.state?.pageNumber })
        const ID = new URLSearchParams(window.location.search)?.get('id') || ''
        if (ID) {
            this.setState({ articleId: ID }, () => this.getData())
        }
    }
    getData= async()=>{
        this.setState({loading:true})
        let res=await apiCall('blogs/'+this.state.articleId+'?apiKey='+apiKey,'GET',null,false,false);
        if(res.status === 200){
            this.setState({ data:res.data})
        }else{
            notificationPop('error', notificationContent.FAILED, content.errorMessage)
        }
        this.setState({loading:false})
    }
    render(){
        return(
            <div className='margins container push-content-down-header'>
                <Spin spinning={this.state.loading}>
                    <Row gutter={[20,0]}>
                        <Col xs={24} sm={24} lg={16}>
                            <Row align='middle' style={{marginBottom:'2%'}}>
                                <Col span={17}>
                                    <Meta
                                        avatar={<Avatar src={this.state.data?.BuyerLogo}/>}
                                        title={this.state.data?.BuyerName}
                                        description={<Space><CheckCircleOutlined className='verified-icon' /><i>Verified Writer</i></Space>}
                                    /></Col>
                                    {/* offset={12} style={{padding:'24px'}}*/}
                                <Col span={7} > 
                                    <Text className='article-text' type="secondary">PUBLISHED ON {moment(this.state.data?.BlogDate).format('MMM, D YYYY').toUpperCase()}</Text>
                                </Col>
                            </Row>
                            
                            <Image className="curved-image" src={this.state.data?.BuyerLogo}></Image>
                            <h2 style={{marginTop: '2%'}}> {this.state.data?.BlogTitle}</h2>
                            <Paragraph className='sub-text-content'>{this.state.data?.BlogText}</Paragraph>
                        </Col>
                        <Col  xs={24} sm={24} lg={8}>
                            {/* <Card className='search-box-card'>
                                <h2 className='search-box-title'>Job Search.</h2> */}
                                <JobSearchBox horizontal={false}/>
                            {/* </Card> */}
                        </Col>
                    </Row>
                </Spin>
            </div>
        )
    }
}
export default ArticleDetail;