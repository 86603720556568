import React, { Component } from 'react'
import { Spin, Button, Form, Select, Row, Col, Card, notification } from 'antd'
import { FOLDER_NAME, SUPPLIER_ID, WEBSITE_NAME } from "../../utils/process";
import { cookie_list, createCookie, getCookie } from '../../utils/cookie';
import CommonFunctionalities from '../../utils/CommonFunctionalities';
import SearchJobDataHandling from '../../utils/SearchJobDataHandling';
import { apiCall } from '../../utils/http';
import moment from 'moment';
import { content } from "../../constants/content";
import "../article/index.css"
import { notificationContent } from '../../constants/notifications';
let apiKey = process.env.REACT_APP_API_KEY
const dateFormat = 'YYYY-MM-DD'
const { Option } = Select
//NOTIFICATION POPUP
function notificationPop(type, header, message) {
    notification.destroy()
    notification[type]({
      message: header,
      description: message
    })
  }
class JobSearchBox extends Component {
    constructor(){
        super()
        this.state={
            loading: false,
            data:null,
            occLoading:false,
            occupations:null, //selected occupations
            originalOccupationsList:[],
            occupationsList:[],
            location: null,
            distance: '',
            longitude: '',
            latitude: '',
            locationList: [],//original location list
            options: [],//modified location list for the dropdown
        }
        this.formRef = React.createRef()
    }
    componentDidMount () {
        this.setGuestUserData()
    }
    setGuestUserData = () => {
        this.setState({
          occupations: getCookie(cookie_list.OCCUPATIONS) ? getCookie(cookie_list.OCCUPATIONS)?.split(',') : [],
          location: getCookie(cookie_list.LOCATION) || null,
          distance: getCookie(cookie_list.DISTANCE) || '10',
          longitude: getCookie(cookie_list.LONGITUDE) || '',
          latitude: getCookie(cookie_list.LATITUDE) || '',
        }, () => {
          this.formRef.current.setFieldsValue({
            occupations: getCookie(cookie_list.OCCUPATIONS) ? getCookie(cookie_list.OCCUPATIONS)?.split(',') : [],
            location: getCookie(cookie_list.LOCATION) || null,
          })
          this.fetchOccupationList()
        })
      }
    fetchOccupationList = async () => {
        await apiCall(`occupations/supplier/${SUPPLIER_ID}?apiKey=${apiKey}`, 'GET').then((res) => {
            if(res?.status === 200 && res?.data?.length > 0) {
                this.fetchOccupationCount(res.data);
                this.setState({ 
                    originalOccupationsList: res.data,
                    occLoading: false
                })
            } else {
                this.setState({ occLoading: false })
            }
        }).catch(() => {
            this.setState({ occLoading: false })
        })
    }
    fetchOccupationCount = async (occupationList=this.state.originalOccupationsList) =>{
        let url = 'apiKey=' + apiKey + '&cpa=true&cpac=true&cpc=true&countryCode=GB';
        if ((getCookie(cookie_list.LATITUDE) && getCookie(cookie_list.LONGITUDE)) || this.state.location) {
          if(this.state.latitude && this.state.longitude){
            url += '&longitude=' + this.state.longitude
            url += '&latitude=' + this.state.latitude
          } else {
            url += '&longitude=' + getCookie(cookie_list.LONGITUDE)
            url += '&latitude=' + getCookie(cookie_list.LATITUDE)
          }
        }
        if (getCookie(cookie_list.DISTANCE) || this.state.distance!=='') {
          if(this.state.distance!==''){
            url += '&distance=' + this.state.distance;
          }else{
            url += '&distance=' + getCookie(cookie_list.DISTANCE);
          }
        }
        occupationList.map((item) => {
          url += "&occupationIds=" + item.Id
        })
            // if (this.state.selectedCompany && this.state.selectedCompany!=='Show all'){
            //   url += "&companyNames=" + this.state.selectedCompany
            // }
        await apiCall('search/jobcountbyoccupations?'+url, 'GET').then((res) => {
          if (res?.status === 200) {
            let occs=[];
            occupationList.forEach((item) => {
              const filterItem=res?.data?.filter((countItem)=>countItem.OccupationId == item.Id);
              if(filterItem.length>0){
                if(filterItem[0].Count>0){
                  // item.label = item.Occupation + ' (' + filterItem[0].Count + ')';
                  occs.push(item);
                }
              }
            })
            //removing the selected occupation item if not in the new occupation list
            let selectedOccupations=this.state.occupations;
            this.state.occupations.forEach(element => {
              let occupationObj = occs.find((occItem)=> occItem.Id.toString()===element);
              if(!occupationObj){
                selectedOccupations=selectedOccupations.filter((item)=>item!==element);
              }
            });
            this.formRef.current.setFieldsValue({
              occupations: selectedOccupations.length>0?selectedOccupations:[],
            })
    
            this.setState({ occupationsList: occs, occupations: selectedOccupations })
          }
          else {
            notificationPop('error', notificationContent.FAILED, res?.message)
          }
          // this.setState({ loader: false })
        }).catch(() => {
          notificationPop('error', notificationContent.FAILED, 'Try again later')
          // this.setState({ loader: false })
        })
    };
    //returns the modified location string
    locationStringfuction =(loc)=>{
        return ''+loc.City+ (loc.City!=='' && loc.County!==''? ', ':'') +loc.County
    }
    //onlocation string change
    setLocationStates = (e) =>{
        let location = this.state.locationList?.find((loc) => this.locationStringfuction(loc) === e)
        if(location){
          this.setState({ location: e, longitude: location.Longitude, latitude: location.Latitude},()=>{
            this.fetchOccupationCount()
          })
        }
    };
    //on search of location (API call)
    fetchLocationList = async (e) => {
        if(e) {
            let locationTyped=e.replace(/\s/g, "");
            let url = `CityAddresses?apiKey=${apiKey}&countryCode=GB&city=` + locationTyped
            await apiCall(url, 'GET', '', true).then((res) => {
            if (res?.status === 200) {
                let data = []
                if (res?.data?.length > 0) {
                data = res?.data?.map((item,index) => { return { value: CommonFunctionalities.SearchLocationOptionValue(item), key: index } })
                this.setState({
                    locationList: res?.data,
                    options: data,
                })
                }
            }
            })
        }
    };
    
    validateFields = () => {
        this.formRef.current.validateFields().then(() => {
            //if there are no validation issues then save the searched location and occupation
            this.saveCurrentLocation()
        }).catch(() => {
          //DO NOTHING
        })
    };
    saveCurrentLocation = async () => {
        let url = `users/location?apiKey=${apiKey}`
        let formdata = {}
        let location = null
        let longitude = ''
        let latitude = ''
        if (this.state.locationList?.length > 0) {
            location = this.state.locationList?.find((loc) => ''+loc.City+ (loc.City!=='' && loc.County!==''? ', ':'') +loc.County === this.state.location)
            if (location) {
            formdata.MaxDistanceKm = 0
            formdata.LocationName = ''+location.City+ (location.City!=='' && location.County!==''? ', ':'') +location.County
            formdata.Country = location.Country
            formdata.Longitude = location.Longitude
            formdata.Latitude = location.Latitude
            formdata.LocationType = location.Type
            formdata.CreatedOn = moment().format(dateFormat)
            formdata.ApiKey = apiKey

            longitude = location.Longitude
            latitude = location.Latitude
            }
        }
        //SAVE OCCUPATIONIN THE COOKIES/API
        await SearchJobDataHandling.searchData(this.state.occupations);
        await apiCall(url, 'POST', formdata, false, true).then(() => {
            //SET COOKIES
            createCookie(cookie_list.LOCATION, this.state.location, 4320, '/')
            createCookie(cookie_list.DISTANCE, this.state.distance, 4320, '/')
            createCookie(cookie_list.LONGITUDE, longitude || this.state.longitude, 4320, '/')
            createCookie(cookie_list.LATITUDE, latitude || this.state.latitude, 4320, '/')
            createCookie(cookie_list.LOCATIONTYPE, formdata.LocationType || '', 4320, '/')
            createCookie(cookie_list.COUNTRY, formdata.Country || '', 4320, '/')
            //REDIRECT TO JOB LIST
            window.location.href = '/job-search'
        })
    };
    renderVerticalLayout = () => {
        return(
            <>
                
                        <Form.Item
                        name='occupations'
                        rules={[{ required: true, message: "Select closest Occupation from the given suggestions" }]}>
                        <Select
                            size='middle'
                            loading={this.state.occLoading}
                            allowClear
                            mode="multiple"
                            maxTagCount='responsive'
                            placeholder="Select occupations"
                            onChange={(e) => this.setState({ occupations: e })}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {this.state.occupationsList?.map((item) => (
                            <Option key={item.Id}>{item.Occupation}</Option>
                            ))}
                        </Select>
                        </Form.Item>
                
                    
                        <Form.Item
                        name='location'
                        rules={[
                            {
                            required: true,
                            message: "Enter Preferred Job Location",
                            },
                        ]}
                        >
                        <Select
                            size='middle'
                            showSearch
                            options={this.state.options}
                            filterOption={(input, option) =>  option.value.toLowerCase().includes(input.toLowerCase())}
                            value={this.state.location}
                            placeholder={content.locationPlaceholder}
                            onChange={(e) => this.setLocationStates(e)}
                            onSearch={this.fetchLocationList}
                            notFoundContent={null}
                        />
                        </Form.Item>
                
                {/* <Col xs={24} sm={24} lg={4}>
                    <Form.Item>
                    <Select
                        value={this.state.distance}
                        onChange={(e) => this.setState({ distance: e },()=>{this.fetchOccupationCount()})}
                        className="w-100"
                    >
                        <Option value='5'>Within 5 miles</Option>
                        <Option value='10'>Within 10 miles</Option>
                        <Option value='15'>Within 15 miles</Option>
                        <Option value='20'>Within 20 miles</Option>
                        <Option value='25'>Within 25 miles</Option>
                        <Option value='50'>Within 50 miles</Option>
                        <Option value='100'>Within 100 miles</Option>
                        <Option value='250'>Within 250 miles</Option>
                        <Option value='500'>Within 500 miles</Option>
                    </Select>
                    </Form.Item>
                </Col> */}
               
                        <Form.Item name="submit">
                        <Button onClick={this.validateFields} type="primary" size='large'>
                            {content.ViewJobs}
                        </Button>
                        </Form.Item>
                   
            </>
        );
    };
    render(){
        return(
            <Spin spinning={this.state.loading}>
                <Card className='search-box-card'>
                    <h2 className='search-box-title'>Job Search.</h2>
                    <Form
                    layout="horizontal"
                    requiredMark={false}
                    ref={this.formRef}
                    className="search-form text-left"
                    >
                        {this.props.horizontal?(
                        <Row gutter={[16, 0]}>
                            <Col xs={24} sm={24} lg={9}>
                                <Form.Item
                                name='occupations'
                                rules={[{ required: true, message: "Select closest Occupation from the given suggestions" }]}>
                                <Select
                                    size='middle'
                                    loading={this.state.occLoading}
                                    allowClear
                                    mode="multiple"
                                    maxTagCount='responsive'
                                    placeholder="Select occupations"
                                    onChange={(e) => this.setState({ occupations: e })}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {this.state.occupationsList?.map((item) => (
                                    <Option key={item.Id}>{item.Occupation}</Option>
                                    ))}
                                </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} lg={9}>
                                <Form.Item
                                name='location'
                                rules={[
                                    {
                                    required: true,
                                    message: "Enter Preferred Job Location",
                                    },
                                ]}
                                >
                                <Select
                                    size='middle'
                                    showSearch
                                    options={this.state.options}
                                    filterOption={(input, option) =>  option.value.toLowerCase().includes(input.toLowerCase())}
                                    value={this.state.location}
                                    placeholder={content.locationPlaceholder}
                                    onChange={(e) => this.setLocationStates(e)}
                                    onSearch={this.fetchLocationList}
                                    notFoundContent={null}
                                />
                                </Form.Item>
                            </Col>
                            {/* <Col xs={24} sm={24} lg={4}>
                                <Form.Item>
                                <Select
                                    value={this.state.distance}
                                    onChange={(e) => this.setState({ distance: e },()=>{this.fetchOccupationCount()})}
                                    className="w-100"
                                >
                                    <Option value='5'>Within 5 miles</Option>
                                    <Option value='10'>Within 10 miles</Option>
                                    <Option value='15'>Within 15 miles</Option>
                                    <Option value='20'>Within 20 miles</Option>
                                    <Option value='25'>Within 25 miles</Option>
                                    <Option value='50'>Within 50 miles</Option>
                                    <Option value='100'>Within 100 miles</Option>
                                    <Option value='250'>Within 250 miles</Option>
                                    <Option value='500'>Within 500 miles</Option>
                                </Select>
                                </Form.Item>
                            </Col> */}
                            <Col xs={24} sm={24} lg={6}>
                                <Form.Item name="submit">
                                <Button onClick={this.validateFields} type="primary" size='large'>
                                    {content.ViewJobs}
                                </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        ):this.renderVerticalLayout()}
                    </Form>
                </Card>
            </Spin>
        )
    }
}
export default JobSearchBox;