import React, { Component } from 'react'
import { Tabs } from 'antd'
import { WEBSITE_NAME } from "../../utils/process";
import UserProfile from './tabs/userprofile'
import CV from './tabs/cvs'
import Applications from './tabs/applications'
import SearchCriteria from './tabs/searchcriteria'
import { getCookie, cookie_list } from '../../utils/cookie'

const { TabPane } = Tabs

class MenuIndex extends Component {
  constructor() {
    super()
    this.state = {
      tab: 'userprofile',
      token: getCookie(cookie_list.TOKEN) || "",
    }
  }

  componentDidMount() {  
    if (!this.state.token) {
      window.location.href = '/'
    }
  }
  renderMainComponent(key){
    switch (this.props?.title) {
        case 'your Details':return(<UserProfile />)
        case 'CVs': return( <CV />);
        case 'Applications': return(<Applications />);
        case 'Search Email Criteria':return(<SearchCriteria />);
        default: return(<UserProfile />)
    }
  }

  render() {
    document.title = 'Profile - ' + WEBSITE_NAME
    return (
      <div className='push-content-down-header sm-margins container'>
        {this.renderMainComponent()}
      </div>
    )
  }
}

export default MenuIndex;