import { cookie_list, createCookie, getCookie } from "./cookie";
import { apiCall } from "./http";
import moment from "moment";

let apiKey = process.env.REACT_APP_API_KEY
class SearchJobDataHandling{
    //API call to fetch the date and set the cookies
    static restoreCookies= async ()=>{
        const res= await apiCall(`users/occupations?apiKey=${apiKey}`,"GET",null,false,true);
        if(res.status===200 && res.data.length>0){
            let occupationIds = res.data.map((item)=>{return item.OccupationId.toString();})
            createCookie(cookie_list.OCCUPATIONS, occupationIds, 4320, '/')
        }
    };
    //API call to save the cookie value in the db
    static restoreUserData= async (cookieOccupations) =>{
        let body=JSON.stringify({OccupationIds: cookieOccupations})
        await apiCall(`users/occupations?apiKey=${apiKey}`,"POST",body,false,true);
    };
    //Functionality to restore the db occupation data
    static checkUserData = async() =>{
        let occupations=getCookie(cookie_list.OCCUPATIONS)
        if(getCookie(cookie_list.TOKEN)){
            if(occupations){
                await this.restoreUserData(occupations.split(','));
            }
        }
        return true;
    };
    //Functionality to save the data on job search
    static searchData= async (occupations=null) =>{
        let CookieOccupations=getCookie(cookie_list.OCCUPATIONS)?.split(',');
        CookieOccupations=CookieOccupations?.map((item)=> {return parseInt(item)});
        if(occupations){
            if((CookieOccupations && JSON.stringify(CookieOccupations) !== JSON.stringify(occupations))|| CookieOccupations === undefined){
                if(getCookie(cookie_list.TOKEN)) {
                    //save the info in the API(POST)
                   await this.restoreUserData(occupations);
                }
                createCookie(cookie_list.OCCUPATIONS, occupations, 4320, '/');
            }
        } else {
            if(getCookie(cookie_list.TOKEN)) {
                if(!CookieOccupations)
                {
                    await this.restoreCookies();
                }
            }
        }        
    }
    //API call to save the location cookie value in the db
    static restoreLocationData = async ()=>{
        let cookieLocation=getCookie(cookie_list.LOCATION)
        let cookieLatitue=getCookie(cookie_list.LATITUDE)
        let cookieLongitude=getCookie(cookie_list.LONGITUDE)
        let cookieCountry=getCookie(cookie_list.COUNTRY)
        let cookieLocationType=getCookie(cookie_list.LOCATIONTYPE)
        if(getCookie(cookie_list.TOKEN)){
            if(cookieLocation && cookieLatitue && cookieLongitude){
                let formdata=JSON.stringify({
                    MaxDistanceKm : 0,
                    LocationName : cookieLocation,
                    Country : cookieCountry,
                    Longitude : cookieLongitude,
                    Latitude : cookieLatitue,
                    LocationType : cookieLocationType,
                    CreatedOn : moment().format('YYYY-MM-DD'),
                    ApiKey : apiKey
                });
                await apiCall(`users/location?apiKey=${apiKey}`,"POST",formdata,false,true);
            }
        }
        return true;
    }
}
export default SearchJobDataHandling;