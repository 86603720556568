import React, { Component } from 'react'
import {Spin, Typography, Col, Row, Result } from 'antd'
import { FOLDER_NAME, WEBSITE_NAME } from "../../utils/process";
import { content } from '../../constants/content'
import {SmileOutlined} from '@ant-design/icons'
const { Text, Title } = Typography
class Employer extends Component {
  constructor() {
    super()
    this.state = {
      loader: false,
      isModalVisible: false
    }
  }
  render() {
    document.title = 'Employer - ' + WEBSITE_NAME
    return (
      <Spin spinning={this.state.loader}>
        <div className='titlemargin bg-position' style={{ backgroundImage: `url(${require('../../assets/' + FOLDER_NAME + '/employer-bg.jpg')})` }}>
          <div className='vc_column-inner container'>
            <Title className='mb-0'><span className='accent'>{content.PostJobs}</span></Title>
            <Title level={3} className='text-white my-0'>{content.ContactSubtitle}</Title>
          </div>
        </div>
          <Result
              icon={<SmileOutlined />}
              title="Thanks, we will be in touch."
            />
      </Spin>
    )
  }
}
export default Employer
