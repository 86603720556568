import React, { Component } from 'react'
import { Card, List, Spin, Button, Typography, notification} from 'antd'
import { apiCall } from '../../utils/http';
import ArticleCard from './articleCard';
import "../article/index.css"
import JobSearchBox from '../jobsearchbox';
import { content } from '../../constants/content';
import { notificationContent } from '../../constants/notifications';
let apiKey = process.env.REACT_APP_API_KEY
const {Text}=Typography;
//NOTIFICATION POPUP
function notificationPop(type, header, message) {
    notification.destroy()
    notification[type]({
      message: header,
      description: message
    })
}
class Article extends Component {
    constructor(){
        super()
        this.state={
            loading: false,
            data:[],
            pageNumber:1,
            totalArticles:0,
            pageSize:20,
        }
    }
    componentDidMount () {
        this.getData();
    }
    getData=async()=>{
        //API call
        this.setState({loading:true})
        let res=await apiCall('blogs?apiKey='+apiKey+'&pageNumber='+this.state.pageNumber,'GET',null,false,false);
        if(res.status === 200){
            this.setState({ data:res.data?.Results, pageSize:res.data?.PageSize, totalArticles:res.data?.Total})
        }else{
            notificationPop('error', notificationContent.FAILED, content.errorMessage)
        }
        this.setState({loading:false})
    }
    renderCard=(item)=>{
        return (
            <ArticleCard article={item}/>
        )
    };
    redirectToDetails = (item) =>{
        this.props.history.push(
            `/article-detail?id=${item.Id}`,
            { pageNumber: this.state.pageNumber }
          )
    };

    render(){
        return(
            <div className='sm-margins container push-content-down-header'>
                <Spin spinning={this.state.loading}>
                    {/* card */}
                    <List
                        // header={<div>Header</div>}
                        // footer={<div>Footer</div>}
                        bordered={false}
                        split={false}
                        dataSource={this.state.data}
                        renderItem={(item,itemIndex) => (
                            <>
                                <List.Item onClick={()=>this.redirectToDetails(item)}>
                                    {/* <Typography.Text mark>[ITEM]</Typography.Text> {item} key={itemIndex}  */}
                                    <Card className='article-card'>
                                        <ArticleCard article={item}/>
                                    </Card>
                                </List.Item>
                                {itemIndex===0 || itemIndex%6===0?(
                                    <List.Item style={{display:'block'}}>
                                        {/* <Card className='search-box-card'>
                                            <h2 className='search-box-title'>Job Search.</h2> */}
                                            <JobSearchBox horizontal={true}/>
                                        {/* </Card> */}
                                    </List.Item>
                                ):null}
                            </>
                        )}
                        pagination={{
                            onChange: (page) => {
                               this.setState({pageNumber:page})
                            },
                            current:this.state.pageNumber,
                            pageSize: this.state.pageSize,
                            total:this.state.totalArticles
                        }}
                        />
                </Spin>
            </div>
        )
    }
}
export default Article;