import React, { Component } from 'react'
import axios from 'axios'
import { Spin, Button, List, Radio, Typography, Form, Upload, notification, Row, Col, Space, Popconfirm, Divider } from 'antd'
import { cookie_list, getCookie } from "../../utils/cookie";
import { apiCall } from '../../utils/http'
import moment from 'moment'
import { DownloadOutlined, UploadOutlined, DeleteOutlined, WarningOutlined } from "@ant-design/icons"
import { content, getOS } from '../../constants/content'
import { notificationContent } from '../../constants/notifications'

const { Title, Text } = Typography

let apiKey = process.env.REACT_APP_API_KEY
let os = getOS()
//NOTIFICATION POPUP
function notificationPop(type, header, message) {
    notification.destroy()
    notification[type]({
        message: header,
        description: message
    })
}

class CVList extends Component {
    constructor() {
        super()
        this.state = {
            loader: false,
            cvlist: [],
            selectedCV: '',
            showCheckbox: true,
        }
    }

    componentDidMount() {
        this.setState({ showCheckbox: this.props?.showCheckbox })
        this.fetchCvList()
    }

    fetchCvList = async () => {
        this.setState({ loader: true })
        let url = `users/getcvs?apiKey=${apiKey}`
        await apiCall(url, 'GET', '', false, true).then((res) => {
            if (res?.status === 200) {
                this.setState({ cvlist: res?.data })
                if (this.props && this.props.getCVListCount) {
                    this.props.getCVListCount(res?.data.length);
                }
            }
            this.setState({ loader: false })
        })
    }

    downloadCV = async (id, filename) => {
        await axios({
            url: `https://appapi.applygateway.com/v1/users/downloadcv?id=${id}&apiKey=${apiKey}`,
            method: "GET",
            responseType: "blob",
            headers: {
                "content-type": "application/json",
                Accept: "application/json",
                Authorization: 'Bearer ' + getCookie(cookie_list.TOKEN)
            },
        })
            .then((response) => {
                const urls = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = urls;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
    }
    deleteCV = async (id) => {
        await apiCall('/users/deletecv?apiKey=' + apiKey + '&id=' + id, 'DELETE', null, false, true).then((res) => {
            if (res?.data?.Success) {
                notificationPop('success', notificationContent.SUCCESS, content.DeleteCVMessage)
                this.fetchCvList()
            } else {
                notificationPop('error', notificationContent.FAILED, res?.data?.message || content.errorMessage)
            }
        }).catch(() => {
            notificationPop('error', notificationContent.FAILED, content.errorMessage)
        })
    }
    onChange = (e) => {
        this.setState({ selectedCV: e.target.value })
        this.props.passCVId(e.target.value);
    };
    resetCVSelect = () => {
        this.setState({ selectedCV: '' })
    };
    uploadCv = async (values) => {
        let formData = new FormData();
        formData.append("file", values.upload.file.originFileObj);
        formData.append("FileName", values.upload.file.name);
        formData.append("Source", os);
        formData.append("TalentIncCvReviewConsent", true);
        formData.append("AllowAgentContacts", true);
        formData.append("ApiKey", apiKey);

        await apiCall('users/cv', 'POST', formData, false, true).then((res) => {
            if (res?.status === 200) {
                notificationPop('success', notificationContent.SUCCESS, 'CV uploaded successfully.')
                this.fetchCvList()
            } else {
                notificationPop('error', notificationContent.FAILED, res?.data?.message)
            }
            this.setState({ loader: false })
        })
    }

    checkFileType = (file) => {
        let extension = file.name.substring(file.name.lastIndexOf('.') + 1);
        const fileSize = file.size / 1024 / 1024; // in MiB

        if (extension !== "pdf" && extension !== "docx" && extension !== "doc" && extension !== "rtf") {
            notificationPop('warning', notificationContent.FAILED, "Your file must be a .doc, .pdf, .docx, or .rtf.")
            return Upload.LIST_IGNORE;
        }
        else if (fileSize > 1) {
            notificationPop('warning', notificationContent.FAILED, "Max. file size: 1 MB.")
            return Upload.LIST_IGNORE;
        }
        else {
            return true
        }
    }
    downloadDeleteButtonRender = (item) => {
        return (
            <>
                <span className="w-first-col">
                    <Text strong className='mr-2'>{item.DisplayName}</Text>
                </span>
                <span className="updated-date">
                    <Title level={5} className='text-capitalize my-1' style={{ fontWeight: '400' }}>Uploaded {moment(item.CreatedOn).format('MMMM Do YYYY')}</Title>
                </span>
                <Space>
                    <Button type='text' icon={<DownloadOutlined style={{ fontSize: 'x-large' }} />} onClick={() => this.downloadCV(item.Id, item.DisplayName)}></Button>

                    <Popconfirm
                        className='ant-btn qcont'
                        title={content.confirmDeleteCVMessage}
                        onConfirm={() => this.deleteCV(item.Id)}
                        okText="Yes"
                        cancelText="No"
                        icon={
                            <WarningOutlined
                                style={{
                                    color: 'red',
                                }}
                            />
                        }
                    >
                        <Button type='text' icon={<DeleteOutlined style={{ fontSize: 'x-large' }} />}></Button>
                    </Popconfirm>
                </Space>
            </>
        );
    }

    render() {
        const props = {
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
                authorization: 'authorization-text',
            },

            // onChange(info) {
            //     if (info.file.status !== 'uploading') {
            //         console.log(info.file, info.fileList);
            //     }

            //     if (info.file.status === 'done') {
            //         alert(`${info.file.name} file uploaded successfully`);
            //     } else if (info.file.status === 'error') {
            //         alert(`${info.file.name} file upload failed.`);
            //     }
            // },
        };
        return (
            <Spin spinning={this.state.loader}>
                <List className='cv-list' itemLayout="horizontal" dataSource={this.state.cvlist}
                    header={
                        <Typography.Text strong className="text-uppercase">
                            Select from your CV list
                        </Typography.Text>
                    }

                    footer={<></>}
                    renderItem={item => (
                        <List.Item className="list-hover">
                            {this.state.showCheckbox ? (<>
                                <Radio.Group className="w-100 table-radio" onChange={this.onChange} value={this.state.selectedCV}>
                                    <Radio value={item.Id}>
                                        <span className="w-first-col">
                                            <Text strong className='mr-2'>{item.DisplayName}</Text>
                                        </span>
                                        <span className="updated-date">
                                            <Title level={5} className='text-capitalize my-1' style={{ fontWeight: '400' }}>Uploaded {moment(item.CreatedOn).format('MMM Do YYYY')}</Title>
                                        </span>
                                        <Button type='text' icon={<DownloadOutlined style={{ fontSize: 'x-large' }} />} onClick={() => this.downloadCV(item.Id, item.DisplayName)}></Button>
                                    </Radio>
                                </Radio.Group></>
                            ) : (
                                this.downloadDeleteButtonRender(item)
                            )}
                        </List.Item>
                    )}></List>
                {this.state.showCheckbox ? (null) : (
                    <Form
                        className='cv-upload-form'
                        onFinish={this.uploadCv}
                        layout="vertical"
                        requiredMark={false}
                    >
                        <Form.Item name='upload' label="Upload a CV"
                            rules={[
                                {
                                    required: true,
                                    message: 'Upload your CV',
                                }
                            ]}
                        >
                            <Upload {...props}>
                                <Button beforeUpload={this.checkFileType} type='primary' icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                        <div><Text type="secondary">{content.MaxFile}</Text></div>
                        <p><Text type="secondary">{content.FileType}</Text></p>
                        <Form.Item name='submit'>
                            <Button htmlType='submit' type="primary" size="large">UPLOAD</Button>
                        </Form.Item>
                    </Form>
                )
                }
            </Spin>
        )
    }
}
export default CVList
