import { Button, Layout, Image, Menu, Drawer } from 'antd'
import React, { Component } from 'react'
import { logout, getCookie, cookie_list } from '../utils/cookie'
import { FOLDER_NAME } from "../utils/process";
import { content } from '../constants/content'
import { MenuOutlined, CloseOutlined } from "@ant-design/icons"

const { Header } = Layout

const pathnames = [
  {
    path: '/job-search',
    class: 'header-light header-height-100 transparent-header',
  },
  {
    path: '/login',
    class: 'header-light header-height-100 transparent-header',
  },
  {
    path: '/employer-contact',
    class: 'header-light header-height-100 transparent-header',
  },
  {
    path: '/',
    class: 'header-light header-height-100 bg-white',
  },
]
//ALL PATHS THAT NEED TRANSPARENT HEADER TRANSITION
const page_paths = ['/job-search','/login','/employer-contact']

class LayoutHeader extends Component {
  showMobileHeaderMenu = () => {
    this.setState({ mobileHeaderMenu: true })
  }
  closeMobileHeaderMenu = () => {
    this.setState({ mobileHeaderMenu: false })
  }
  constructor(props) {
    super(props)
    this.state = {
      token: getCookie(cookie_list.TOKEN),
      email: getCookie(cookie_list.EMAIL),
      firstName: getCookie(cookie_list.FIRSTNAME),
      mobileHeaderMenu: false,
      classname: pathnames?.find((pathname) => pathname?.path === window.location?.pathname)?.class || 'header-light header-height-100 bg-white',
      path: pathnames?.find((pathname) => pathname?.path === window.location?.pathname)?.path || '/',
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.updateHeader)
  }

  updateHeader = () => {
    if(page_paths?.includes(this.state.path)) {
      if(window.scrollY >= 25) {
        this.setState({ classname: 'header-light header-height-80 bg-white' })
      } else {
        this.setState({ classname: 'header-light header-height-100 transparent-header' })
      }
    }
    else {
      if(window.scrollY >= 25) {
        this.setState({ classname: 'header-light header-height-80 bg-white' })
      } else {
        this.setState({ classname: 'header-light header-height-100 bg-white' })
      }
    }
  } 

  render() {
    return (
      <Header className={this.state.classname}>
        <div className='px-0 container'>
          <div className="logo">
            <Button type='text' className='h-unset p-0' onClick={() => window.location.href = '/'}><span className={FOLDER_NAME === 'housingjobs' ? 'logo-lg' : 'logo-md'}><Image src={require('../assets/' + FOLDER_NAME + '/logo.png')} preview={false} alt='applygateway' /></span></Button>
          </div>
          <div className="pc-navbar top-nav text-uppercase">
            {this.state.token ?
              <Menu mode="horizontal">
                <Menu.Item key="article"><a href='/article' className='primary-link'>Blog</a></Menu.Item>
                <Menu.Item key="user"><a href='/profile' className='primary-link'><span className='text-capitalize'>Hi, </span><span className='text-capitalize'>{this.state.firstName}</span></a></Menu.Item>
                <Menu.Item key="logout" onClick={() => logout()}>
                  Logout
                </Menu.Item>
              </Menu>
              :
              <Menu mode="horizontal">
                <Menu.Item key="article"><a href='/article' className='primary-link'>Blog</a></Menu.Item>
                <Menu.Item key="signin" onClick={() => window.location.href = '/login'}>
                  {content.SignIn}
                </Menu.Item>
                <Menu.Item key="register" onClick={() => window.location.href = '/login'}>
                  {content.Register}
                </Menu.Item>
              </Menu>
            }
          </div>

          <div className="mobile-menu-div text-right">
            <Button
              type='primary'
              icon={<MenuOutlined />}
              onClick={this.showMobileHeaderMenu}
            />
          </div>
          {/* Mobile Menu Start */}
          <div className="mobile-menu-div">
            <Drawer
              placement="top"
              className="mobile-menu"
              closable={false}
              onClose={this.closeMobileHeaderMenu}
              visible={this.state.mobileHeaderMenu}
            >
              <div className='text-right'><Button type="primary" icon={<CloseOutlined />} size="large" onClick={this.closeMobileHeaderMenu} /></div>
              {this.state.token ?
                <Menu mode="inline">
                  <Menu.Item key="user"><span className='text-capitalize'>Hi, </span><span className='text-capitalize'>{this.state.firstName}</span></Menu.Item>
                  {/* <div className="mobile-menu-div"> */}
                    <Menu.Item key="article"><a href='/article' className='primary-link'>Blog</a></Menu.Item>
                    <Menu.Item key="details" className="mobile-menu-div"><a href='/your-details' className='primary-link'>Your Details</a></Menu.Item>
                    <Menu.Item key="cvs" className="mobile-menu-div"><a href='/cvs' className='primary-link'>CVs</a></Menu.Item>
                    <Menu.Item key="applications" className="mobile-menu-div"><a href='/applications' className='primary-link'>Applications</a></Menu.Item>
                    <Menu.Item key="search-email-criteria" className="mobile-menu-div"><a href='/search-email-criteria' className='primary-link'>Search Email Criteria</a></Menu.Item>
                  {/* </div> */}
                  <Menu.Item key="logout" onClick={() => logout()}>
                    Logout
                  </Menu.Item>
                </Menu>
                :
                <Menu mode="inline">
                  <Menu.Item key="signin" onClick={() => window.location.href = '/login'}>
                    {content.SignIn}
                  </Menu.Item>
                  <Menu.Item key="register" onClick={() => window.location.href = '/login'}>
                    {content.Register}
                  </Menu.Item>
                  <Menu.Item key="article"><a href='/article' className='primary-link'>Blog</a></Menu.Item>
                </Menu>
              }
            </Drawer>
          </div>
        </div>
      </Header>
    )
  }
}

export default LayoutHeader