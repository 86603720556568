import React, { Component } from 'react'
import { Button, Checkbox, Form, Input, notification, Spin, Row, Col, Typography } from 'antd'
import { notificationContent } from '../../../constants/notifications'
import { apiCall } from '../../../utils/http'
import { content } from '../../../constants/content'

const { Text } = Typography

let apiKey = process.env.REACT_APP_API_KEY

//NOTIFICATION POPUP
function notificationPop(type, header, message) {
  notification.destroy()
  notification[type]({
    message: header,
    description: message
  })
}

class UserProfile extends Component {
  constructor() {
    super()
    this.state = {
      loader: true
    }
  }

  formRef = React.createRef();

  componentDidMount() {
    this.fetchUserDetails()
  }

  fetchUserDetails = async () => {
    let url = `users?apiKey=${apiKey}`
    await apiCall(url, 'GET', '', false, true).then((res) => {
      console.log('Get user response' + res);
      if (res) {
        if (res.status === 200) {
          console.log('User details:' + res.data);
          console.log('User res.data.DailyEmails:' + res.data.DailyEmails);
          this.formRef.current.setFieldsValue({
            email: res.data.Email,
            firstname: res.data.FirstName,
            lastname: res.data.LastName,
            mobilephone: res.data.Telephone,
            dailyemails: res.data.DailyEmails,
          });
        }
      }
      this.setState({ loader: false })
    })
  }

  updateUser = async (values) => {
    console.log("values", values)
    // eslint-disable-next-line no-undef

    let form_data = {
      Name: `${values.firstname} ${values.lastname}`,
      Email: values.email,
      SendDailyEmails: values.dailyemails,
      Telephone: values.mobilephone,
      SalaryRange: 'From1To10000',
      ApiKey: apiKey
    }

    await apiCall('users', 'PUT', form_data, false, true).then((res) => {
      console.log('Update user res: ' + res);
      if (res?.status === 200) {
        console.log('Update user res: ' + res.status);
        console.log('Success res: ' + res.data.success);
        notificationPop('success', notificationContent.SUCCESS, 'User details updated successfully')
      } else {
        console.log('Update user res: ' + res.status);
        notificationPop('error', notificationContent.FAILED, res?.data?.message)
      }
      this.setState({ loader: false })
    })
  }

  render() {
    return (
      <Spin spinning={this.state.loader}>
        <div>
          <Form
            onFinish={this.updateUser}
            ref={this.formRef}
            layout="vertical"
            requiredMark={false}
          >
            <Row gutter={[16, 0]}>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  className='mb-0'
                  label="Name"
                  name='firstname'
                  rules={[{ required: true, message: 'First name is required' }]}
                >
                  <Input />
                </Form.Item>
                <div className='mb-2'><Text type="secondary">First name</Text></div>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  label="lastname"
                  name='lastname'
                  rules={[{ required: true, message: 'Last name is required' }]}
                  className="label-hidden mb-0"
                >
                  <Input />
                </Form.Item>
                <div className='mb-2'><Text type="secondary">Last name</Text></div>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name='email'
                  label={content.Email}
                  rules={[
                    {
                      required: true,
                      message: 'Enter your Email'
                    },
                    {
                      type: 'email',
                      message: 'Invalid email'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} lg={12}>
                <Form.Item
                  name='mobilephone'
                  label={content.Phone}
                  rules={[
                    {
                      required: true,
                      message: 'Enter your Phone number',
                    },
                    {
                      pattern: '^[- +()0-9]{10,}$',
                      message: 'Invalid Phone Number'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name='dailyemails' valuePropName="checked">
              <Checkbox>{content.DailyEmails}</Checkbox>
            </Form.Item>


            <Form.Item>
              <Button htmlType="submit" type="primary" size="large">Save</Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    );
  }
}

export default UserProfile;