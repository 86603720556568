import React, { Component } from 'react'
import { Layout, Row, Col, Menu, Typography, Button, Image, Drawer, Space, Card, Form, Input } from "antd"
import { FOLDER_NAME, CONTACT_US_LINK } from "../utils/process";
import { content } from '../constants/content';
import { MenuOutlined, CloseOutlined } from "@ant-design/icons"
const { Footer } = Layout
const { Title, Text } = Typography
class LayoutFooter extends Component {
  showMobileMenu = () => {
    this.setState({ mobileMenu: true })
  }
  closeMobileMenu = () => {
    this.setState({ mobileMenu: false })
  }
  constructor(props) {
    super(props)
    this.state = {
      mobileMenu: false
    }
  }
  render() {
    return (
      <div>
        <Footer className='app-footer relative'>
          <div className='container px-0'>
            <Row gutter={[16, 8]}>
              <Col xs={24} md={12}>
                <Button type='text' className='h-unset mb-30' onClick={() => window.location.href = '/'}><Image src={require('../assets/' + FOLDER_NAME + '/footer-logo.png')} preview={false} alt='applygateway' /></Button>
              </Col>
              <Col xs={24} md={6}>
                <Title level={4} className='text-white text-uppercase'>Jobseeker</Title>
                <ul className='pl-0'>
                  <li><a href='/job-search'>Browse jobs</a></li>
                  <li><a href='/login'>Job alerts</a></li>
                </ul>
              </Col>
              <Col xs={24} md={6}>
                <Title level={4} className='text-white text-uppercase'>Employer</Title>
                <ul className='pl-0'>
                  <li><a href='/employer-contact'>Post your jobs</a></li>
                </ul>
              </Col>
              <Col xs={24} md={24}>
                <div className='pc-navbar ml-3'>
                  <Menu mode="horizontal">
                    <Menu.Item
                      onClick={() => window.location.href = CONTACT_US_LINK}
                      key="Contact">
                      Contact Us
                    </Menu.Item>
                    <Menu.Item key="Terms"
                      onClick={() => window.location.href = '/terms-conditions'}
                    >
                      Terms and conditions
                    </Menu.Item>
                    <Menu.Item key="Privacy"
                      onClick={() => window.location.href = '/privacy-policy'}>
                      Privacy policy
                    </Menu.Item>
                    <Menu.Item key="DataProcessing"
                      onClick={() => window.location.href = '/data-processing-policy'}>
                      Data processing policy
                    </Menu.Item>
                    <Menu.Item key="copyrights" disabled={true}>
                      {'©' + FOLDER_NAME + '2022'}
                    </Menu.Item>
                  </Menu>
                </div>
                <div className="mobile-menu-div text-center">
                  <Space>
                    <Button
                      ghost
                      icon={<MenuOutlined />}
                      onClick={this.showMobileMenu}
                    >Company</Button>
                  </Space>
                </div>
                {/* Mobile Menu Start */}
                <div className="mobile-menu-div">
                  <Drawer
                    placement="bottom"
                    className="mobile-menu"
                    closable={false}
                    onClose={this.closeMobileMenu}
                    visible={this.state.mobileMenu}
                  >
                    <div className='text-right'><Button type="primary" icon={<CloseOutlined />} size="large" onClick={this.closeMobileMenu} /></div>
                    <Menu mode="inline">
                      <Menu.Item key="Contact">
                        Contact Us
                      </Menu.Item>
                      <Menu.Item key="Terms">
                        Terms and conditions
                      </Menu.Item>
                      <Menu.Item key="Privacy">
                        Privacy policy
                      </Menu.Item>
                    </Menu>
                  </Drawer>
                  <div className='text-center pt-2'>{'©' + FOLDER_NAME + '2022'}</div>
                </div>
              </Col>
            </Row>
          </div>
        </Footer>
      </div>
    )
  }
}

export default LayoutFooter
