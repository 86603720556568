class CommonFunctionalities{
    //Function to return search location text
    static SearchLocationOptionValue = (item) => {
        let optionValue= item.City;
        if(item.City!=="" && item.County!==""){
            optionValue+=", ";
        }
        optionValue+=item.County; 
        return optionValue;
    };
    static PostCodeText = (postCode) =>{
        if(postCode){
            var postStr = postCode.replace(/\s/g, "");
            var postcodeModifiedText;
            if(postStr.length<=6){
                //show 3 characters
                postcodeModifiedText = postStr.slice(0,3);
            }else{
                //show 4 characters
                postcodeModifiedText = postStr.slice(0,4);
            }
            return postcodeModifiedText;
        }
        return postCode;
        
        
    };
}
export default CommonFunctionalities;