export const notifications = {
  SOMETHING_WRONG: 'Something went wrong. Please try again later.',
  INVAL_USERNAME: 'Invalid Username.',
  FAILED: 'Failed.',
  SUCCESS: 'Success.',
  AgreeNotifications2: ["I agree to these ", <a href="/login">Terms & Conditions</a>, " and this ", <a href="/login">Privacy Policy</a>, " and to receive email notifications and alerts."],
  TermsOfUse: ["By registering for this account, you're agreeing to our ", <a href="/login">Terms & Conditions</a>, " and confirm that you have read our ", <a href="/login">Privacy Policy</a>, " and our ", <a href="/login">Cookie Use Policy.</a>],
  ConfirmYou: "Confirm it is you",
  EmailedOTP: "We emailed you a one time passcode. It may take a moment to arrive.",

  FOLDERNAME_CJ: 'carejobs',
  FOLDERNAME_HJ: 'housingjobs',
  FOLDERNAME_NJ: 'nursesjobs',
  FOLDERNAME_ChefJ: 'chefjobs',
  FOLDERNAME_CouncilJ: 'counciljobs',
  WebsiteName_CJ: "Care Jobs ",
  WebsiteName_HJ:"Housing Jobs ",
  WebsiteName_NJ:"Nurses Jobs ",
  WebsiteName_ChefJ:"Chef Jobs ",
  WebsiteName_CouncilJ:"Council Jobs ",
  Content_CJ: 'care',
  Content_HJ: 'housing',
  Content_NJ: 'nurses',
  Content_ChefJ: 'Chef',
  Content_CouncilJ: 'Council',
  SupplierId_CJ: '103', //carejobs
  SupplierId_HJ: '106', //housingjobs
  SupplierId_NJ: '107', //nursesjobs
  SupplierId_ChefJ: '124', //chefjobs
  SupplierId_CouncilJ: '108', //counciljobs
  LogoURL_CJ: '/carejobslogo.png',
  LogoURL_HJ: '/housingjobslogo.png',
  LogoURL_NJ: '/nursesjobslogo.png',
  LogoURL_ChefJ: '/chefjobslogo.png',
  LogoURL_CouncilJ: '/counciljobslogo.png',
}
