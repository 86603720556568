import { notifications } from '../utils/contants'

// PAGES START
let FOLDERNAME, WEBSITENAME, SUPPLIERID, CONTENTNAME, CONTACTUSLINK, LOGOURL;
switch (process.env.REACT_APP_ENV) {
    case 'carejobs':
    case 'local':
    case 'development':
        FOLDERNAME = notifications.FOLDERNAME_CJ;
        WEBSITENAME = notifications.WebsiteName_CJ;
        SUPPLIERID = notifications.SupplierId_CJ;
        CONTENTNAME = notifications.Content_CJ;
        CONTACTUSLINK = 'https://aemail.com/581v';
        LOGOURL = notifications.LogoURL_CJ;
        break;
    case 'housingjobs':
        FOLDERNAME= notifications.FOLDERNAME_HJ;
        WEBSITENAME = notifications.WebsiteName_HJ;
        SUPPLIERID = notifications.SupplierId_HJ;
        CONTENTNAME = notifications.Content_HJ;
        CONTACTUSLINK = 'https://aemail.com/66zl';
        LOGOURL = notifications.LogoURL_HJ;
        break;
    case 'nursesjobs': 
        FOLDERNAME= notifications.FOLDERNAME_NJ;
        WEBSITENAME = notifications.WebsiteName_NJ;
        SUPPLIERID = notifications.SupplierId_NJ;
        CONTENTNAME = notifications.Content_NJ;
        CONTACTUSLINK = 'https://aemail.com/76Wr';
        LOGOURL = notifications.LogoURL_NJ;
        break;
    case 'counciljobs':
        FOLDERNAME= notifications.FOLDERNAME_CouncilJ;
        WEBSITENAME = notifications.WebsiteName_CouncilJ;
        SUPPLIERID = notifications.SupplierId_CouncilJ;
        CONTENTNAME = notifications.Content_CouncilJ;
        CONTACTUSLINK = 'https://aemail.com/581v';
        LOGOURL = notifications.LogoURL_CouncilJ;
        break;
    case 'chefjobs':
        FOLDERNAME= notifications.FOLDERNAME_ChefJ;
        WEBSITENAME = notifications.WebsiteName_ChefJ;
        SUPPLIERID = notifications.SupplierId_ChefJ;
        CONTENTNAME = notifications.Content_ChefJ;
        CONTACTUSLINK = 'https://aemail.com/581v';
        LOGOURL = notifications.LogoURL_ChefJ;
        break;
    default:
        break;
}
export const FOLDER_NAME=FOLDERNAME;
export const WEBSITE_NAME = WEBSITENAME;
export const SUPPLIER_ID = SUPPLIERID;
export const CONTENT_NAME = CONTENTNAME;
export const CONTACT_US_LINK = CONTACTUSLINK;
export const LOGO_URL = LOGOURL;

// PAGES END

// CSS START

export const ACCENT_CSS =  'accent';

// CSS END