import { CONTENT_NAME } from "../utils/process";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons"

export const content = {
    SignIn: 'Sign in',
    Register: 'Register',
    CompleteApplication: 'Complete Application',
    RegisterNow: ['Register now ', <DoubleRightOutlined className="font-xsm" />],
    Login: 'Login',
    SignInSubtitle: 'Thousands of jobs available direct from employers.',
    CreateAccount: 'Create an account',
    CreateAccountDec: 'Apply for jobs with one click, save jobs and manage applications and job alerts with an account.',
    Email: 'Email Address',
    Phone: 'Phone Number',
    PostCode: 'Post Code',
    AgreeNotifications: 'I agree to receive email notifications and alerts.',
    AgreeBlogNotifications: 'I agree to receive email alerts and blog posts',
    AgreeNotifications2: ['I agree to these ', <a href='/login'>Terms & Conditions</a>, ' and this ', <a href='/login'>Privacy Policy</a>, ' and to receive email notifications and alerts.'],
    TermsOfUse: ["By registering for this account, you're agreeing to our ", <a href="/login">Terms & Conditions</a>, " and confirm that you have read our ", <a href="/login">Privacy Policy</a>, " and our ", <a href="/login">Cookie Use Policy.</a>],
    ConfirmYou: 'Confirm it is you',
    EmailedOTP: 'We emailed you a one time passcode. It may take a moment to arrive.',
    FindJobs: 'FIND JOBS',
    Getstarted:'Get started',
    ViewJobs: 'View All Job Matches',
    Update: 'UPDATE',
    Follow: 'Follow',
    ViewAllJobs: 'View all our jobs',
    ContactUs: 'Contact us',
    PostJobs: 'Post your jobs',
    ContactSubtitle:'If you are an employer with jobs in the ' + CONTENT_NAME + ' industry then please contact us.',
    JobSearch: 'Job search',
    SearchAllJobs: 'Search all Jobs',
    JobSubtitle: 'Thousands of jobs available direct from employers.',
    Occupations: 'Occupations',
    Company: 'Company',
    Distance: 'Distance',
    HomeTitle: 'direct from the best employers:',
    Featured: 'Featured companies',
    FeaturedDes:'We partner with the leading employers in the ' + CONTENT_NAME + ' sector to ensure we can offer you the best opportunities on the market.',
    RegisterToday: 'Register today',
    RegisterTodayDes:'Once you register with us you will have access to thousands of jobs from the top ' + CONTENT_NAME + ' employers. Registering takes only a minute so sign up today.',
    JobAlerts: 'Get Job Alerts',
    JobAlertsDesc: 'Don’t miss an opportunity – register your job preferences and get the latest HR jobs directly to your inbox.',
    JobAlertsToday: ['Set up a job alert today ',<DoubleRightOutlined className="font-xsm" />],
    MaxFile: 'Max. file size: 1 MB.',
    FileType: 'Your file must be a .doc, .pdf, .docx, or .rtf.',
    ApplyAll:"Get hired faster by applying to similar roles all at once!",
    Similar: "Why not apply to these similar jobs?",
    DailyEmails:"Daily emails",
    BackToSearch:[<DoubleLeftOutlined />," Back to search results"],
    AboutRole:"About the role",
    ApplyNow:"Apply Now",
    CurrentLocation:"Current Location",
    PreviousLocations:"Previous Locations",
    unsubscribeSuccessMessage:"Thanks, you have now been unsubscribed from email alerts.",
    unsubscribeInvalidLinkMessage:"Invalid Link.",
    errorMessage:"Something went wrong. Please try again later.",
    DeleteCVMessage:'CV deleted successfully.',
    confirmDeleteCVMessage:'Are you sure to delete this CV?',
    ResendEmail:'Email it to me again.',
    sentEmail:'Your code has been resent.',//'Please check your mailbox for the OTP.',
    experienceLabel:'DO YOU HAVE EXPERIENCE IN THE HOUSING SECTOR?',
    carejobsExperienceLabel:'DO YOU HAVE HEALTHCARE/SOCIAL CARE EXPERIENCE?',
    LicenceCarLabel:'Do you have a driving license and access to your own car?',
    noYearsExperienceLabel:'HOW MANY YEARS EXPERIENCE?',
    nursesPinLabel:'NURSES PIN:',
    streetAddressLabel:'Street Address',
    addressLine2Label:'Address Line 2',
    cityLabel:'City',
    postCodeLabel:'PostCode',
    bandLabel:'BAND:',
    licenceLabel:'DO YOU HOLD A UK DRIVING LICENCE?',
    criminalConvictions:'Do you have criminal convictions?',
    locationLabel:'DO YOU CURRENTLY LIVE OUTSIDE OF THE UK?',
    workStatusLabel:'WORK STATUS:',
    aboutYouLabel:'A FEW SENTENCES ABOUT YOU:',
    locationPlaceholder:'Type and Select Place or Postcode....',
    locationSearchLabel:'Location (place or postcode)',
    noJobLabel:'Sorry, this job is no longer available',

  }
  
  export const getOS = () => {
    let operatingSystem = 'Web';
    if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Web'; }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'Ios'; }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Android'; }

    return operatingSystem
  }