import React, { Component } from 'react'
import { Tabs } from 'antd'
import { WEBSITE_NAME } from "../../utils/process";
import UserProfile from './tabs/userprofile'
import CV from './tabs/cvs'
import Applications from './tabs/applications'
import SearchCriteria from './tabs/searchcriteria'
import { getCookie, cookie_list } from '../../utils/cookie'

const { TabPane } = Tabs

class Profile extends Component {
  constructor() {
    super()
    this.state = {
      tab: 'userprofile',
      token: getCookie(cookie_list.TOKEN) || "",
    }
  }

  componentDidMount() {
    let tabNameURL = new URLSearchParams(window.location.search)?.get('tabName');
    if(tabNameURL){
      let tabName;
      switch (tabNameURL) {
        case 'YourDetails':
        case 'yourdetails': tabName='userprofile'
          break;
        case 'cvs':
        case 'CVs': tabName='cvs'
        break;
        case 'applications':
        case 'Applications': tabName='applications'
        break;
        case 'searchemailcriteria':
        case 'SearchEmailCriteria': tabName='searchcriteria'
        break;
        default: tabName='userprofile'
          break;
      }
      this.setState({tab: tabName})
    }    
    if (!this.state.token) {
      window.location.href = '/'
    }
  }

  render() {
    document.title = 'Profile - ' + WEBSITE_NAME
    return (
      <div className='push-content-down-header sm-margins container'>
        <Tabs activeKey={this.state.tab} type="card" onChange={(e) => this.setState({ tab: e })}>
          <TabPane tab='Your Details' key='userprofile'>{this.state.tab === 'userprofile' && <UserProfile />}</TabPane>
          <TabPane tab='CVs' key='cvs'>{this.state.tab === 'cvs' && <CV />}</TabPane>
          <TabPane tab='Applications' key='applications'>{this.state.tab === 'applications' && <Applications />}</TabPane>
          <TabPane tab='Search / Email Criteria' key='searchcriteria'>{this.state.tab === 'searchcriteria' && <SearchCriteria />}</TabPane>
        </Tabs>
      </div>
    )
  }
}

export default Profile;