import React, { createContext } from 'react'
import { Switch, BrowserRouter as Router } from 'react-router-dom'
import { Login, Homepage, CVList } from './pages'
import { LOGO_URL } from "../src/utils/process";
import RouteWithLayout from './routeHandler'
import WebLayout from './components/layout'
import Employer from './pages/employer'
import EmployerContactThankYou from './pages/employer/contactthankyou'
import JobSearch from './pages/jobsearch'
import Profile from './pages/profile'
import JobDetail from './pages/jobdetail'
import Unsubscribe from './pages/unsubscribe'
import TermsAndConditions from './pages/termsandconditions'
import PrivacyPolicy from './pages/privacypolicy'
import DataProcessingPolicy from './pages/dataprocessingpolicy'
import MenuIndex from './pages/profile/menuIndex'
import Article from './pages/article'
import ArticleDetail from './pages/articledetail'
import EmployerDetails from './pages/employerdetails'
import Logo from './pages/logo'

export const State = createContext()
const initialState = {}
function Routes(...rest) {
  return (
    <State.Provider value={initialState}>
      <Router>
        <Switch>
          <RouteWithLayout
            exact
            path='/'
            layout={WebLayout}
            title='HomePage'
            component={Homepage}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/login'
            title='Login'
            layout={WebLayout}
            component={Login}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/profile'
            layout={WebLayout}
            title='Profile'
            component={Profile}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/cvs'
            layout={WebLayout}
            title='CVs'
            component={MenuIndex}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/your-details'
            layout={WebLayout}
            title='Your Details'
            component={MenuIndex}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/applications'
            layout={WebLayout}
            title='Applications'
            component={MenuIndex}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/search-email-criteria'
            layout={WebLayout}
            title='Search Email Criteria'
            component={MenuIndex}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/employer-contact-thanks'
            layout={WebLayout}
            title='Contact Us'
            component={EmployerContactThankYou}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/employer-contact'
            layout={WebLayout}
            title='Contact Us'
            component={Employer}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/terms-conditions'
            layout={WebLayout}
            title='Terms & conditions'
            component={TermsAndConditions}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/privacy-policy'
            layout={WebLayout}
            title='Privacy policy'
            component={PrivacyPolicy}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/data-processing-policy'
            layout={WebLayout}
            title='Data processing policy'
            component={DataProcessingPolicy}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/job-search'
            layout={WebLayout}
            title='Search Job'
            component={JobSearch}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/job-detail'
            layout={WebLayout}
            title='Job Detail'
            component={JobDetail}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/unsubscribe'
            layout={WebLayout}
            title='unsubscribe'
            component={Unsubscribe}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/article'
            layout={WebLayout}
            title='Blog'
            component={Article}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/article-detail'
            layout={WebLayout}
            title='article Detail'
            component={ArticleDetail}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/employer-details'
            layout={WebLayout}
            title='Employer Details'
            component={EmployerDetails}
            {...rest}
          />
          <RouteWithLayout
            exact
            path={LOGO_URL}
            layout={({ children }) => <>{children}</>}
            title='carejobslogo'
            component={Logo}
            {...rest}
          />
        </Switch>
      </Router>
    </State.Provider>
  )
}
export default Routes
