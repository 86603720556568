import React, { Component } from 'react'
import { Spin, Typography } from 'antd'
import { WEBSITE_NAME } from "../../utils/process";
import { content } from '../../constants/content';
import { apiCall } from '../../utils/http';
let apiKey = process.env.REACT_APP_API_KEY
const {Text, Title}=Typography;

class Unsubscribe extends Component {
  constructor() {
    super()
    this.state = {
        loader: false,
        userGuid: null,
        message:content.unsubscribeSuccessMessage
    }
  }
  
  componentDidMount() {
        let GUID = new URLSearchParams(window.location.search)?.get('userGuid') || null
        if(GUID){
            this.unsubscribeUser(GUID)
        } else {
            //show message that link not proper
            this.setState({message:content.unsubscribeInvalidLinkMessage})
        }
  }
    async unsubscribeUser(GUID){
        this.setState({ loader: true })
        await apiCall("/users/unsubscribe?apiKey="+apiKey+"&userGuid="+GUID+"","GET",).then((res) => {
            if (res.status === 200) {
                //success message
                this.setState({message:content.unsubscribeSuccessMessage})
            } else {
                //error message
                this.setState({message:content.errorMessage})
            }
        }).catch(() =>{
            //error message 
            this.setState({message:content.errorMessage})
        })
        this.setState({ loader: false })
    }
  render() {
    document.title = 'Unsubscribe - ' + WEBSITE_NAME
    return (
      <div className='push-content-down-header sm-margins container'>
        <Spin spinning={this.state.loader}>
            <p className="text-left text-lg-center">{this.state.message}</p>
        </Spin>
      </div>
    )
  }
}

export default Unsubscribe;