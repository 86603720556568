import React, { Component } from "react";
import { Button, Form, /*AutoComplete,*/ Select, Row, Col, Typography, Image, Card, Space, notification } from "antd";
import { cookie_list, getCookie, createCookie, refreshCookies } from "../../utils/cookie";
import { FOLDER_NAME, SUPPLIER_ID, WEBSITE_NAME } from "../../utils/process";
import { content } from "../../constants/content";
import { apiCall } from '../../utils/http'
import moment from "moment";
import "../homepage/index.css"
import { MailOutlined, UserOutlined, TeamOutlined, DoubleRightOutlined } from "@ant-design/icons"
import SearchJobDataHandling from "../../utils/SearchJobDataHandling";
import CommonFunctionalities from "../../utils/CommonFunctionalities";
import { notificationContent } from '../../constants/notifications';


let apiKey = process.env.REACT_APP_API_KEY
const { Option } = Select
const dateFormat = 'YYYY-MM-DD'
const { Text, Title } = Typography
//NOTIFICATION POPUP
function notificationPop(type, header, message) {
  notification.destroy()
  notification[type]({
    message: header,
    description: message
  })
}
class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      occLoading: true,
      token: getCookie(cookie_list.TOKEN) || "",
      occupationsList: [],
      originalOccupationsList: [],
      occupations: [],
      location: null,
      distance: '',
      longitude: '',
      latitude: '',

      options: [],
      locationList: [],

      occupationsDisplay: [],
    };
    this.formRef = React.createRef()
  }

  componentDidMount() {
    //OCCUPATION DATA CHECK
    SearchJobDataHandling.searchData();
    if (this.state.token) {
      let canCallAPIs = refreshCookies()
      if (canCallAPIs) {
        if (getCookie(cookie_list.LOCATION) && getCookie(cookie_list.LATITUDE) && getCookie(cookie_list.LONGITUDE)) {
          this.setGuestUserData()
        } else {
          this.fetchCurrentLocation()
          this.fetchOccupationList()
        }
      }
    } else {
      this.setGuestUserData()
    }
  }

  setGuestUserData = () => {
    this.setState({
      occupations: getCookie(cookie_list.OCCUPATIONS) ? getCookie(cookie_list.OCCUPATIONS)?.split(',') : [],
      location: getCookie(cookie_list.LOCATION) || null,
      distance: getCookie(cookie_list.DISTANCE) || '10',
      longitude: getCookie(cookie_list.LONGITUDE) || '',
      latitude: getCookie(cookie_list.LATITUDE) || '',
    }, () => {
      this.formRef.current.setFieldsValue({
        occupations: getCookie(cookie_list.OCCUPATIONS) ? getCookie(cookie_list.OCCUPATIONS)?.split(',') : [],
        location: getCookie(cookie_list.LOCATION) || null,
      })
      this.fetchOccupationList()
    })
  }

  splitArray = (arr, len) => {
    var chunks = [], i = 0, n = arr.length
    while (i < n) {
      chunks.push(arr.slice(i, i += len))
    }
    return chunks
  }

  fetchCurrentLocation = async () => {
    let url = `users/currentlocation?apiKey=${apiKey}`
    await apiCall(url, 'GET', '', false, true).then((res) => {
      if (res) {
        this.setState({
          occupations: getCookie(cookie_list.OCCUPATIONS) ? getCookie(cookie_list.OCCUPATIONS)?.split(',') : [],
          location: res?.status === 200 && res?.data?.LocationName ? res?.data?.LocationName : getCookie(cookie_list.LOCATION),
          longitude: res?.status === 200 && res?.data?.Longitude ? res?.data?.Longitude : getCookie(cookie_list.LONGITUDE),
          latitude: res?.status === 200 && res?.data?.Latitude ? res?.data?.Latitude : getCookie(cookie_list.LATITUDE),
          distance: getCookie(cookie_list.DISTANCE) || '10'
        }, () => {
          this.formRef.current.setFieldsValue({
            occupations: getCookie(cookie_list.OCCUPATIONS) ? getCookie(cookie_list.OCCUPATIONS)?.split(',') : [],
            location: res?.status === 200 && res?.data?.LocationName ? res?.data?.LocationName : getCookie(cookie_list.LOCATION),
          })
        })
      }
    })
  }
  locationStringfuction = (loc) => {
    return '' + loc.City + (loc.City !== '' && loc.County !== '' ? ', ' : '') + loc.County
  }
  setLocationStates = (e) => {
    let location = this.state.locationList?.find((loc) => this.locationStringfuction(loc) === e)
    if (location) {
      this.setState({ location: e, longitude: location.Longitude, latitude: location.Latitude })
    }
  };
  fetchOccupationList = async () => {
    await apiCall(`occupations/supplier/${SUPPLIER_ID}?apiKey=${apiKey}`, 'GET').then((res) => {
      if (res?.status === 200 && res?.data?.length > 0) {
        this.setState({
          occupationsList: res.data,
          occupationsDisplay: this.splitArray(res.data, Math.ceil(res.data.length / 3)),
          occLoading: false
        })
      }
      else {
        this.setState({ occLoading: false })
      }
    }).catch(() => {
      this.setState({ occLoading: false })
    })
  }

  fetchLocationList = async (e) => {
    if (e) {
      let locationTyped = e.replace(/\s/g, "");
      let url = `CityAddresses?apiKey=${apiKey}&countryCode=GB&city=` + locationTyped
      await apiCall(url, 'GET', '', true).then((res) => {
        if (res?.status === 200) {
          let data = []
          if (res?.data?.length > 0) {
            data = res?.data?.map((item, index) => { return { value: CommonFunctionalities.SearchLocationOptionValue(item), key: index } })
            this.setState({
              locationList: res?.data,
              options: data,
            })
          }
        }
      })
    }
  }

  validateFields = () => {
    this.formRef.current.validateFields().then(() => {
      this.saveCurrentLocation()
    }).catch(() => {
      //DO NOTHING
    })
  }

  saveCurrentLocation = async () => {
    let url = `users/location?apiKey=${apiKey}`
    let formdata = {}
    let location = null
    let longitude = ''
    let latitude = ''
    if (this.state.locationList?.length > 0) {
      location = this.state.locationList?.find((loc) => '' + loc.City + (loc.City !== '' && loc.County !== '' ? ', ' : '') + loc.County === this.state.location)
      if (location) {
        formdata.MaxDistanceKm = 0
        formdata.LocationName = '' + location.City + (location.City !== '' && location.County !== '' ? ', ' : '') + location.County
        formdata.Country = location.Country
        formdata.Longitude = location.Longitude
        formdata.Latitude = location.Latitude
        formdata.LocationType = location.Type
        formdata.CreatedOn = moment().format(dateFormat)
        formdata.ApiKey = apiKey

        longitude = location.Longitude
        latitude = location.Latitude
      }
    }
    //SAVE OCCUPATIONIN THE COOKIES/API
    await SearchJobDataHandling.searchData(this.state.occupations);
    await apiCall(url, 'POST', formdata, false, true).then(() => {
      //SET COOKIES
      createCookie(cookie_list.LOCATION, this.state.location, 4320, '/')
      createCookie(cookie_list.DISTANCE, this.state.distance, 4320, '/')
      createCookie(cookie_list.LONGITUDE, longitude || this.state.longitude, 4320, '/')
      createCookie(cookie_list.LATITUDE, latitude || this.state.latitude, 4320, '/')
      createCookie(cookie_list.LOCATIONTYPE, formdata.LocationType || '', 4320, '/')
      createCookie(cookie_list.COUNTRY, formdata.Country || '', 4320, '/')
      //REDIRECT TO JOB LIST
      //window.location.href = '/job-search'
      window.location.href = `/job-search?Location=${encodeURIComponent(this.state.location)}&distance=${encodeURIComponent(this.state.distance)}&occupationIds=${encodeURIComponent(this.state.occupations)}`;


    })
  }

  handleOccupationById = async () => {
    //SET OCCUPATION COOKIE
    await SearchJobDataHandling.searchData([this.state.occupations]);
    window.location.href = '/job-search'
  }

  render() {
    document.title = 'Home - ' + WEBSITE_NAME
    return (
      <div className="push-content-down-header">
        <div className="video-container flex-center text-center bg-style margins-lg" style={{ backgroundImage: `url(${require('../../assets/' + FOLDER_NAME + '/video-bg.jpg')})` }}>
          <video playsInline autoPlay muted loop id="myVideo">
            <source src={require('../../assets/' + FOLDER_NAME + '/videos/mp4-bg.mp4')} type="video/mp4" />
            <source src={require('../../assets/' + FOLDER_NAME + '/videos/webm-bg.webm')} type="video/webm" />
            Your browser does not support HTML5 video. Update or switch browser.
          </video>
          <div className="upb_bg_overlay"></div>
          <div className="upb_bg_overlay_pattern"></div>
          <div className='find-jobs-form container'>
            <div className="px-3">
              <Title level={2} className="text-white mb-4 text-left text-lg-center">Search <span className="accent">{WEBSITE_NAME}</span> {content.HomeTitle}</Title>
              <Form
                layout="horizontal"
                requiredMark={false}
                ref={this.formRef}
                className="search-form text-left"
              >
                <Row gutter={[16, 0]}>
                  <Col xs={24} sm={24} lg={8}>
                    <Form.Item
                      name='occupations'
                      rules={[{ required: true, message: "Select closest Occupation from the given suggestions" }]}>
                      <Select
                        loading={this.state.occLoading}
                        mode="multiple"
                        allowClear
                        maxTagCount='responsive'
                        placeholder="Select occupations"
                        onChange={(e) => this.setState({ occupations: e })}
                        value={this.state.occupations | []}
                        // options={options}
                        optionFilterProp="label"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={(this.state.occupationsList || []).map((d) => ({
                          value: "" + d.Id,
                          label: d.Occupation,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={8} key={"location_input"}>
                    <Form.Item
                      name='location'
                      rules={[
                        {
                          required: true,
                          message: "Enter Preferred Job Location",
                        },
                      ]}
                    >
                      {/* <AutoComplete
                        options={this.state.options}
                        placeholder="Type and Select Location..."
                        onSearch={this.fetchLocationList}
                        onChange={(e) => this.setState({ location: e })}
                        value={this.state.location}
                      /> */}
                      <Select
                        showSearch
                        options={this.state.options}
                        filterOption={(input, option) => option.value.toLowerCase().includes(input.toLowerCase())}
                        value={this.state.location}
                        placeholder={content.locationPlaceholder}
                        onChange={(e) => this.setLocationStates(e)}
                        onSearch={this.fetchLocationList}
                        notFoundContent={null}
                      />
                      {/* {this.state.options?.map((loc) => (
                          <Option value={loc.value} key={loc.value}>{loc.value}</Option>
                        ))}
                      </Select> */}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={4} key={"distance_input"}>
                    <Form.Item>
                      <Select
                        value={this.state.distance}
                        onChange={(e) => this.setState({ distance: e })}
                        className="w-100"
                      >
                        <Option value='5'>Within 5 miles</Option>
                        <Option value='10'>Within 10 miles</Option>
                        <Option value='15'>Within 15 miles</Option>
                        <Option value='20'>Within 20 miles</Option>
                        <Option value='25'>Within 25 miles</Option>
                        <Option value='50'>Within 50 miles</Option>
                        <Option value='100'>Within 100 miles</Option>
                        <Option value='250'>Within 250 miles</Option>
                        <Option value='500'>Within 500 miles</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} lg={4} key={"search_form_submit _btn"}>
                    <Form.Item name="submit">
                      <Button onClick={this.validateFields} type="primary" size="large">
                        {content.FindJobs}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Title level={3} className="text-white text-left text-lg-center">Tired of getting hassled by agents? We only work directly with employers.</Title>
            </div>
          </div>
        </div>
        <div className="theme-2-bg primary-border-top">
          <div className='margins container'>
            <Title level={2} className="text-white pt-35">Browse our <span className="accent">Jobs by Occupation</span></Title>
            <Row gutter={[48, 0]}>
              {this.state.occupationsDisplay?.map((item) => (
                item.map((list_item) => (
                  <Col xs={24} sm={24} lg={8} xl={8} className="border-right" key={list_item.Id}>
                    <Button onClick={() => this.setState({ occupations: list_item.Id }, () => this.handleOccupationById())} type="text" className="text-white font-20-300 px-0 occ-hover">
                      {list_item.Occupation}
                    </Button>
                  </Col>
                ))
              ))}
            </Row>
          </div>
        </div>
        <div className="margins container">
          <Row gutter={[0, 16]}>
            <Col xs={24} md={24} lg={8} xl={8} xxl={8} className="flex-center-column" key={"FC_col_1"}>
              <Title level={2}><span className="accent">{content.Featured}</span> </Title>
              <p>{content.FeaturedDes}</p>
              <Button size="large" href="/job-search" className="outline">{content.SearchAllJobs}</Button>
            </Col>
            <Col xs={24} md={24} lg={16} xl={16} xxl={16} key={"FC_col_2"}>
              <Row gutter={[16, 16]} className="featured-company">
                <Col xs={24} md={24} lg={8} xl={8} xxl={8} key={"FC_1"}>
                  <Image
                    preview={false}
                    src={require('../../assets/' + FOLDER_NAME + '/one.jpg')}
                  />
                </Col>
                <Col xs={24} md={24} lg={8} xl={8} xxl={8} key={"FC_2"}>
                  <Image
                    preview={false}
                    src={require('../../assets/' + FOLDER_NAME + '/two.jpg')}
                  />
                </Col>
                <Col xs={24} md={24} lg={8} xl={8} xxl={8} key={"FC_3"}>
                  <Image
                    preview={false}
                    src={require('../../assets/' + FOLDER_NAME + '/three.jpg')}
                  />
                </Col>
                <Col xs={24} md={24} lg={8} xl={8} xxl={8} key={"FC_4"}>
                  <Image
                    preview={false}
                    src={require('../../assets/' + FOLDER_NAME + '/four.jpg')}
                  />
                </Col>
                <Col xs={24} md={24} lg={8} xl={8} xxl={8} key={"FC_5"}>
                  <Image
                    preview={false}
                    src={require('../../assets/' + FOLDER_NAME + '/five.jpg')}
                  />
                </Col>
                <Col xs={24} md={24} lg={8} xl={8} xxl={8} key={"FC_6"}>
                  <Image
                    preview={false}
                    src={require('../../assets/' + FOLDER_NAME + '/six.jpg')}
                  />
                </Col>
                <Col xs={24} md={24} lg={8} xl={8} xxl={8} key={"FC_7"}>
                  <Image
                    preview={false}
                    src={require('../../assets/' + FOLDER_NAME + '/seven.jpg')}
                  />
                </Col>
                <Col xs={24} md={24} lg={8} xl={8} xxl={8} key={"FC_8"}>
                  <Image
                    preview={false}
                    src={require('../../assets/' + FOLDER_NAME + '/eight.jpg')}
                  />
                </Col>
                <Col xs={24} md={24} lg={8} xl={8} xxl={8} key={"FC_9"}>
                  <Image
                    preview={false}
                    src={require('../../assets/' + FOLDER_NAME + '/nine.jpg')}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="margins bg-style" style={{ backgroundImage: `url(${require('../../assets/' + FOLDER_NAME + '/register-bg.jpg')})` }}>
          <div className="container">
            <Row gutter={[32, 16]}>
              <Col xs={24} sm={24} lg={12} xl={12} xxl={12} className="flex-center-column">
                <div className="pr-4">
                  <Title level={2} className="pt-35 mb-0"><span className="accent">{content.RegisterToday}</span> </Title>
                  <p className="pt-20 mb-0">{content.RegisterTodayDes}</p>
                  <Button size="large" href="/login" className="outline mt-30">{content.RegisterToday}</Button>
                </div>
              </Col>
              <Col xs={24} sm={24} lg={12} xl={12} xxl={12}></Col>
            </Row>
          </div>
        </div>
        <div className="margins container mb-5">
          <Row gutter={[40, 16]}>
            <Col xs={24} sm={24} lg={16} xl={16} xxl={16} key={"getJob-createAccount"}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} key={"get_job_alert"}>
                  <Card className="text-center bg-gray p-2 pb-3">
                    <Button shape="circle" size="large" icon={<MailOutlined />} className="icon-lg" />
                    <Title level={3}><span className="accent-2">{content.JobAlerts}</span></Title>
                    <p className="mb-0">{content.JobAlertsDesc}</p>
                    <Button onClick={() => window.location.href = '/login'} type="text" size="large" className="qcont"><Text strong><span className="accent-2 gap-5 font-20">{content.JobAlertsToday}</span></Text></Button>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} key={"create-an-account"}>
                  <Card className="text-center bg-gray p-2 pb-5">
                    <Button shape="circle" size="large" icon={<UserOutlined />} className="icon-lg" />
                    <Title level={3}><span className="accent-2">{content.CreateAccount}</span></Title>
                    <p className="mb-0">{content.CreateAccountDec}</p>
                    <Button onClick={() => window.location.href = '/login'} type="text" size="large" className="qcont"><Text strong><span className="accent-2 gap-5 font-20">{content.RegisterNow}</span></Text></Button>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} lg={8} xl={8} xxl={8} className="flex-center-column" key={"advertise-reasons"}>
              <Title level={3}><Space><Button shape="circle" size="large" icon={<TeamOutlined />} className="icon-lg bg-gray" /><span className="accent-2">Employers</span></Space></Title>
              <p className="mb-2"><Text strong>Why advertise with us?</Text></p>
              <ul>
                <li key={"reason_1"}>{'UK’s leading ' + WEBSITE_NAME + ' board.'}</li>
                <li key={"reason_2"}>Reach relevant and qualified candidates.</li>
                <li key={"reason_3"}>No recruitment consultants only direct employer jobs.</li>
              </ul>
              <Button onClick={() => window.location.href = '/employer-contact'} type="text" size="large" className="qcont"><Text strong><span className="accent-2 gap-5 font-20">{content.PostJobs} <DoubleRightOutlined className="font-xsm" /></span></Text></Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default HomePage;
