// GOOGLE EVENTS
export const googleAnalyticsRegister = () => {
  window.gtag('send', 'event', 'Register', 'SuccessPage', 'Register', 1, {'nonInteration':false})
}
export const googleAnalyticsApplyOffSite = () => {
  window.gtag('send', 'event', 'Applies', 'Button', 'ApplyOffSite', 1, {'nonInteration':false})
}
export const googleAnalyticsApplyOnSite = () => {
  window.gtag('send', 'event', 'Applies', 'SuccessPage', 'ApplyOnSite', 1, {'nonInteration':false})
}

// FACEBOOK EVENTS
export const facebookAnalyticsRegister = () => {
  window.fbq('track', 'CompleteRegistration')
}

export const facebookAnalyticsSubmit = () => {
  window.fbq('track', 'SubmitApplication');
}
